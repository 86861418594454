import Header from "../Component/Header"
import React from "react"
import { Text, View, ScrollView,Image } from "react-native";
import { componentstyle, textstyle } from "../assets/Style";
import { ButtonStandard } from '../Component/Button';
import { BigInputFormMutliple } from "../Component/Input";
import AsyncStorage from "@react-native-async-storage/async-storage";
import Moment from 'moment';

class DetailsImage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            type: "Visage",
        }
        
    }
    data={
        client: "Buffet Raphael",
        date: "12.01.2012",
        humidity: "20",
        note: "8"
    }
    componentDidMount(){
        AsyncStorage.getItem('analyse')
			.then(analyses => {
				analyses = JSON.parse(analyses)
				console.log(analyses)
                this.data=analyses
				console.log(this.data)
                this.forceUpdate()
			})
    }
    render() {
        return (
            <View style={componentstyle.container}>
                <Header />
                <ScrollView>
                    <View style={componentstyle.form}>
                        <View style={componentstyle.textcontainer}>
                            <Text style={textstyle.soustitre}>{this.data.firstname} {this.data.lastname}</Text>
                        </View>
                        <View style={componentstyle.textcontainer}>
                            <Text style={textstyle.soustitre}>Analyse du {Moment(this.data.date).format('DD.MM.YYYY')}</Text>
                        </View>
                    </View>
                    <View style={componentstyle.form}>
                    <View style={componentstyle.imagecontainer}>
                    <Image
                                style={componentstyle.imagebig}
                                source={require('../assets/Image/example.jpg')} />
                                </View>
                    <View style={componentstyle.componentsimple}>
                        <Text style={textstyle.textbold}>critère 1 :</Text>
                        <Text style={textstyle.textbold}>critère 2 :</Text>
                        <Text style={textstyle.textbold}>critère 3 :</Text>
                        <Text style={textstyle.textbold}>critère 4 :</Text>
                    </View>
                    </View>
                    <View style={componentstyle.form}>
                        <Text style={textstyle.soustitre}>Diagnostique détaillée</Text>
                    </View>
                    <BigInputFormMutliple />
                    <View style={componentstyle.form}>
                        <View style={componentstyle.textcontainer}>
                            <Text style={textstyle.textbold}>Humidité de la peau : {this.data.humidity}%</Text>
                        </View>
                        <View style={componentstyle.textcontainer}>
                            <Text style={textstyle.textbold}>Score générale : {this.data.note}/10</Text>
                        </View>
                    </View>
                    <ButtonStandard
                        onPress={() => {
                            this.props.navigation.goBack()
                        }}>
                        Enregistrer
                </ButtonStandard>
                </ScrollView>
            </View>
        )
    }
}

export default DetailsImage
