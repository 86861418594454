import React from 'react';
import { Text, View, ScrollView, TouchableOpacity } from "react-native"
import Header from '../Component/Header';
import { componentstyle, textstyle } from "../assets/Style";
import axios from 'axios';
import AsyncStorage from "@react-native-async-storage/async-storage";
import config from '../config';
import Moment from 'moment';


var datas = []
class Appareil extends React.Component {
	state = {
		user: "exemple",
		mail: "exemple",
		téléphone: "exemple",
		patient: "exemple",
		appareil: "exemple",
		type: "",
		
	}
	data= []
	componentDidMount() {
		AsyncStorage.getItem('devices')
			.then(devices => {
				devices = JSON.parse(devices).devices
				this.data=devices
				console.log("Appareil Screen Mount")
				console.log(devices)
				this.displaydata()
				this.forceUpdate()
			})
	}
	render() {
		
		return (
			<View style={componentstyle.container}>
				<Header />
				<ScrollView>
					<View style={componentstyle.textcontainer}>
						<Text style={textstyle.titre2}>Appareils</Text>
						<View>
							<View style={componentstyle.tab}>
								<View style={componentstyle.tabcontent}>
									<Text style={textstyle.text}>Référence de l'appareil</Text>
								</View>
								<View style={componentstyle.tabcontent}>
									<Text style={textstyle.text}>Version du Système</Text>
								</View>
								<View style={componentstyle.tabcontent}>
									<Text style={textstyle.text}>Dernière synchronisation</Text>
								</View>
								<View style={componentstyle.tabcontent}>
									<Text style={textstyle.text}>Voir les détails</Text>
								</View>
							</View>
							{datas}
						</View>
					</View>
				</ScrollView>
			</View>
		)
	}

	details(data,index,client){
		let note=Math.floor(Math.random() * 4) + 6
		AsyncStorage.removeItem('device2')
		AsyncStorage.setItem('device2', JSON.stringify({			
			id:data[index].id,
		}));
		{console.log("AppareilEdit OK")}
		console.log(this.data[index].id)
		this.props.navigation.navigate('EditAppareil')
	}

	displaydata() {
		datas = [];
		console.log(this.data)
		for (let i = 0; i < this.data.length; i++) {
			console.log("ici")
			
			{
				datas.push(
					<View key={i}>
						<View style={componentstyle.tab}>
							<View style={componentstyle.tabcontent}>
								<Text style={textstyle.text}>{this.data[i].id}</Text>
							</View>
							<View style={componentstyle.tabcontent}>
								<Text style={textstyle.text}>{this.data[i].version}</Text>
							</View>
							<View style={componentstyle.tabcontent}>
								<Text style={textstyle.text}>{Moment(this.data[i].date).format('d.MM.YYYY')}</Text>
							</View>
							<View style={componentstyle.tabcontent}>
								<TouchableOpacity onPress={() => this.details(this.data,i,i)}>
									<Text style={textstyle.link}> view details </Text>
								</TouchableOpacity>
							</View>
						</View>
					</View>
				)
			}
		}
	}
}

export default Appareil