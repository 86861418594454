import {createBottomTabNavigator} from "react-navigation-tabs";
import ProfilScreen from "./ProfilScreen.js";
import AnalyseScreen from "./AnalyseScreen";
import ContactScreen from "./ContactScreen";
import DeconnexionScreen from "../Commun/DeconnexionScreen";
import { APP_COLORS } from "../assets/Color.js";

const AppDrawerNavigatorClient = createBottomTabNavigator({
    Profil: {
        screen: ProfilScreen,
        navigationOptions: {
            tabBarOptions: {
                style: {
                   backgroundColor: APP_COLORS.button,
                },
                labelStyle: {
                    fontSize: 28,
                    margin: 0,
                    padding: 0,
                  }
              }
        },
        path: '/',
    },
    Analyse: {
        screen: AnalyseScreen,
        navigationOptions: {
            tabBarOptions: {
                style: {
                   backgroundColor: APP_COLORS.button,
                },
                labelStyle: {
                    fontSize: 28,
                    margin: 0,
                    padding: 0
                  }
              }
        },
        path: '/',
    },
    Contact: {
        screen: ContactScreen,
        navigationOptions: {
            tabBarOptions: {
                style: {
                   backgroundColor: APP_COLORS.button,
                },
                labelStyle: {
                    fontSize: 28,
                    margin: 0,
                    padding: 0
                  }
              }
        },
        path: '/',
    },
    Parametre:{
        screen: DeconnexionScreen,
        navigationOptions: {
            tabBarOptions: {
                style: {
                   backgroundColor: APP_COLORS.button,
                },
                labelStyle: {
                    fontSize: 28,
                    margin: 0,
                    padding: 0
                  }
              }
        },
        path: '/',
    },
});

export default AppDrawerNavigatorClient;