import React from "react"
import Store from './Store/configureStore'
import { View } from "react-native"
import {componentstyle} from "./assets/Style"
import Coskin from "./Main/Coskin";
import { Provider } from 'react-redux';

class App extends React.Component {
	render() {
		return (
			<Provider store={Store}>
			<View style={componentstyle.container}>
				<Coskin/>
			</View>
			</Provider>
		)
	}
}

export default App