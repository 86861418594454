import React from 'react';
import { Text, View, ScrollView } from "react-native"
import Header from '../Component/Header';
import { componentstyle, textstyle } from "../assets/Style";
import { ButtonStandard } from '../Component/Button';
import { InputForm } from '../Component/Input';
import AsyncStorage from "@react-native-async-storage/async-storage";
import config from '../config';
import axios from 'axios';

var id
class Profil extends React.Component {
	
	state = {
		nom: "exemple",
		email: "exemple",
		telephone: "exemple",
		nombrePatient: "exemple",
		nombreAppareil: "exemple",
		edit: false,
		oldpassword: "",
		newpassword: "",
		newpassword2: "",
		passwordedit: false,
		buttontext: "Modifier mes données",
		error: ""
	}
	switchEdit() {
		this.state.edit = !this.state.edit
		this.forceUpdate()
		console.log(this.state.edit)
	}
	save() {
		axios.post(config.backEndURL + config.backEndApiURL + 'users/' + id, {
			username: this.state.nom,
			mail: this.state.email,
			telephone: this.state.telephone,
		})
			.then((res) => {
				console.log(res)
			})
		AsyncStorage.setItem('user', JSON.stringify({
			id: id,
			username: this.state.nom,
			email: this.state.email,
			phoneNumber: this.state.telephone,
			type: "user",
			isClient: false,
			isLogged: true
		}));

		this.state.edit = !this.state.edit
		this.forceUpdate()
	}
	switchMotDePasse() {
		this.state.passwordedit = !this.state.passwordedit
		this.state.edit = false
		this.forceUpdate()
		console.log(this.state.passwordedit)
	}
	changepassword() {
		if (this.state.newpassword == this.state.newpassword2) {
			axios.post(config.backEndURL + config.backEndApiURL + 'users/password/' + id, {
				old: this.state.oldpassword,
				password: this.state.newpassword
			})
				.then((result) => {
					console.log("result")
					console.log(result.data.status)
					if (!result.data.status) {
						this.setState({ error: "ancien mot de passe incorrect" })
					}
					else {
						this.state.passwordedit = !this.state.passwordedit
						this.state.edit = false
						this.forceUpdate()
					}
				})
		}
		else {
			this.setState({ error: "les nouveau mot de passe doive correspondre" })
		}


	}
	componentDidMount() {
		console.log("Profil")
		setTimeout(() => {
			let utilisateur
			let mail
			let telephone
			let appareil
			let client
			AsyncStorage.getItem('user')
				.then(user => {
					user = JSON.parse(user)
					id = user.id
					utilisateur = user.username
					mail = user.email
					telephone = user.phoneNumber
					console.log(user)
				})
				console.log("devices2")
				AsyncStorage.getItem('devices')
				.then(devices => {
					console.log(devices)
					devices = JSON.parse(devices).devices
					console.log(devices)
					appareil=devices.length
					console.log(devices)
				})
				AsyncStorage.getItem('clients')
				.then(clients => {
					console.log(clients)
					clients = JSON.parse(clients).clients
					console.log(clients)
					client=clients.length
					console.log(clients)
				})
				
			setTimeout(() => {
				this.setState({
					nom: utilisateur,
					email: mail,
					telephone: telephone,
					nombrePatient: client,
					nombreAppareil: appareil
				})
			}, 800)
		}, 800)
	}
	render() {
		return (
			<View style={componentstyle.container}>
				<Header />
				<ScrollView>
					<View style={componentstyle.textcontainer}>
						<Text style={textstyle.titre2}>Votre profil</Text>
						<View style={componentstyle.form}>
							<View style={componentstyle.formelement}>
								<Text style={textstyle.text}>Nom d'utilisateur:</Text>
							</View>
							{!this.state.edit ?
								<View style={componentstyle.formelementlittle}>
									<Text style={textstyle.text}>{this.state.nom}</Text>
								</View>
								:
								<InputForm
									onChangeText={(nom) => this.setState({ nom })}
									value={this.state.nom}
									secure={false}
								/>
							}
						</View>
						<View style={componentstyle.form}>
							<View style={componentstyle.formelement}>
								<Text style={textstyle.text}>Adresse e-mail :</Text>
							</View>
							{!this.state.edit ?
								<View style={componentstyle.formelementlittle}>
									<Text style={textstyle.text}>{this.state.email}</Text>
								</View>
								:
								<InputForm
									onChangeText={(email) => this.setState({ email })}
									value={this.state.email}
									secure={false}
								/>
							}

						</View>
						<View style={componentstyle.form}>
							<View style={componentstyle.formelement}>
								<Text style={textstyle.text}>Numéro de téléphone :</Text>
							</View>
							{!this.state.edit ?
								<View style={componentstyle.formelementlittle}>
									<Text style={textstyle.text}>{this.state.telephone}</Text>
								</View>
								:
								<InputForm
									onChangeText={(telephone) => this.setState({ telephone })}
									value={this.state.telephone}
									secure={false}
								/>
							}
						</View>
						<View style={componentstyle.form}>
							<View style={componentstyle.formelement}>
								<Text style={textstyle.text}>Nombre de patient :</Text>
							</View>
							<View style={componentstyle.formelementlittle}>
								<Text style={textstyle.text}>{this.state.nombrePatient}</Text>
							</View>
						</View>
						<View style={componentstyle.form}>
							<View style={componentstyle.formelement}>
								<Text style={textstyle.text}>Nombre d'appareil :</Text>
							</View>
							<View style={componentstyle.formelementlittle}>
								<Text style={textstyle.text}>{this.state.nombreAppareil}</Text>
							</View>
						</View>
						{!this.state.passwordedit ?
							<View>
								{!this.state.edit ?
									<View>
										<ButtonStandard onPress={() => { this.switchEdit() }}>Modifier mes données</ButtonStandard>
										<ButtonStandard onPress={() => { this.switchMotDePasse() }}>Changer mon mot de passe</ButtonStandard>
									</View>
									:
									<View>
										<ButtonStandard onPress={() => { this.save() }}>Enregistrer</ButtonStandard>
										<ButtonStandard onPress={() => { this.switchMotDePasse() }}>Changer mon mot de passe</ButtonStandard>
									</View>}

							</View>
							:
							<View>
								<View style={componentstyle.form}>
									<View style={componentstyle.formelement}>
										<Text style={textstyle.text}>Ancien mot de passe</Text>
									</View>
									<InputForm
										onChangeText={(oldpassword) => this.setState({ oldpassword })}
										value={this.state.oldpassword}
										secure={true}
									/>
								</View>
								<View style={componentstyle.form}>
									<View style={componentstyle.formelement}>
										<Text style={textstyle.text}>Nouveau mot de passe</Text>
									</View>
									<InputForm
										onChangeText={(newpassword) => this.setState({ newpassword })}
										value={this.state.newpassword}
										secure={true}
									/>
								</View>
								<View style={componentstyle.form}>
									<View style={componentstyle.formelement}>
										<Text style={textstyle.text}>Nouveau mot de passe</Text>
									</View>
									<InputForm
										onChangeText={(newpassword2) => this.setState({ newpassword2 })}
										value={this.state.newpassword2}
										secure={true}
									/>
								</View>
								<View style={componentstyle.textcontainer}>
									<Text style={{ color: 'red', fontSize: 32 }}>{this.state.error}</Text>
								</View>
								<ButtonStandard onPress={() => { this.changepassword() }}>Enregistrer</ButtonStandard>
								<ButtonStandard onPress={() => { this.switchMotDePasse() }}>Annuler</ButtonStandard>
							</View>
						}

					</View>
				</ScrollView>
			</View>
		)
	}
}

export default Profil