import React from 'react';
import { Text, View,ScrollView } from "react-native";
import { componentstyle, textstyle } from "../assets/Style";
import { ButtonRetour,ButtonBig } from '../Component/Button';
import Header from '../Component/Header';
import { InputForm } from '../Component/Input'

class MotDePasseOublie extends React.Component {
	state = {
		email:"",
		utilisateur:""
	}
	render() {
		return (
			<View style={componentstyle.container}>
                <Header/>
                <ScrollView>
                <View style={componentstyle.textcontainer}>
                    <View style={componentstyle.form}>
                        <View style={componentstyle.formelement}>
                            <Text style={textstyle.text}>Nom d'utilisateur :</Text>
                        </View>
                        <InputForm
                            onChangeText={(utilisateur) => this.setState({ utilisateur })}
                            value={this.state.utilisateur}
                            secure={false}
                        />
                    </View>
                    <View style={componentstyle.form}>
                        <View style={componentstyle.formelement}>
                            <Text style={textstyle.text}>E-mail :</Text>
                        </View>
                        <InputForm
                            onChangeText={(email) => this.setState({ email })}
                            value={this.state.email}
                            secure={false}
                        />
                    </View>
                </View>
                <ButtonRetour onPress={() => this.props.navigation.goBack()}>Retour</ButtonRetour>
                <ButtonBig onPress={() => this.props.navigation.navigate('Accueil')}>Demande de mot de passe</ButtonBig>
                </ScrollView>
            </View>
		)
	}
}
export default MotDePasseOublie