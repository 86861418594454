import React from 'react';
import { Text, View, Image, StyleSheet, TouchableOpacity, ScrollView } from "react-native"
import Header from '../Component/Header';
import { componentstyle, textstyle } from "../assets/Style";
import { Picker } from '@react-native-picker/picker';
import AsyncStorage from "@react-native-async-storage/async-storage";
import Moment from 'moment';
import axios from 'axios';
import config from '../config'

const styles = StyleSheet.create({
	container: {
	  paddingTop: 50,
	},
	tinyLogo: {
	  width: 50,
	  height: 50,
	},
	logo: {
	  width: 66,
	  height: 58,
	},
  });

var tabView= []
var tabViewLocal = []
var imagesort = []

class Anaylse extends React.Component {

	state = {
		user: "exemple",
		mail: "exemple",
		téléphone: "exemple",
		patient: "exemple",
		appareil: "exemple",
		type: "",
		medecin: "",
	}
	data = []
	imagefolder2 = []

	// Lancement au démarage d'AnalyseScreen (1 fois seulement)
	componentDidMount() {
		console.log("ComponentDidMount")
		let medecinNom
		AsyncStorage.getItem('medecin')
			.then(medecin => {
				medecin = JSON.parse(medecin)
				medecinNom=medecin.nom
			})
		let id

		// Id de l'utilisateur (Client)
		AsyncStorage.getItem('user')
		.then(user => {
			user = JSON.parse(user)
			id=user.id
			console.log(id)
		})

		let imageinfoRec = []
		AsyncStorage.getItem('imageinfos')
		.then(imageinfos => {
			// Prend toutes les analyses du client ('id, path, nbimage, timestanp')
			imageinfos = JSON.parse(imageinfos).imageinfos
			imageinfoRec = imageinfos
		})


		AsyncStorage.getItem('analyses')
			.then(analyses => {
				// Prend toutes les analyses du client ('id, path, nbimage, timestanp')
				analyses = JSON.parse(analyses).analyses
				console.log(analyses)
				
				// Parcours les analyses 1 à X
				for(let i=0;i<analyses.length;i++){
					// Pour chaque analyse parcours les Image
					
					// prendre les infos des images et mettre dans la variable global
					this.data.push({
							date:analyses[i].timestamp,
							appareil:analyses[i].id,
							version:analyses[i].version,
							medecin:medecinNom,
							nbrImg:analyses[i].nbimage,
							idclient:id,
							path:analyses[i].path,
							clients:analyses[i].clients,
							idMeasurement:analyses[i].idMeasurement,
							imageInfos:imageinfoRec[i].imageinfosAll,
						})
				}
				console.log(this.data)

			})
			console.log(this.data)
			setTimeout(() => {
				console.log("setTimeOut")
				this.displaydata()
				this.displayImage()
			}, 1500)
	}
	render() {

		return (
			<View style={componentstyle.container}>
				<Header />


				<ScrollView>
					<View style={componentstyle.textcontainer}>
						<Text style={textstyle.titre2}>Anaylse</Text>
						<View style={componentstyle.standardView}>
							<Picker
								style={componentstyle.Picker}
								selectedValue={this.state.type}
								onValueChange={(value) => {
									this.setState({ type: value })
								}}
							>
								<Picker.Item label="Date" value="Date" />
								<Picker.Item label="Image" value="Image" />
							</Picker>
						</View>
						{/* ternaire condition ? valeur true : valeur false */}
						{this.state.type == "Image" ? <View>
							<View style={componentstyle.tab}>
							</View>
								{imagesort}
							</View>
							: <View>
								<View style={componentstyle.tab}>
									<View style={componentstyle.tabcontent}>
										<Text style={textstyle.text}>Date</Text>
									</View>
									<View style={componentstyle.tabcontent}>
										<Text style={textstyle.text}>Appareil</Text>
									</View>
									<View style={componentstyle.tabcontent}>
										<Text style={textstyle.text}>Version</Text>
									</View>
									<View style={componentstyle.tabcontent}>
										<Text style={textstyle.text}>Médecin</Text>
									</View>
									<View style={componentstyle.tabcontent}>
										<Text style={textstyle.text}>Rapport</Text>
									</View>
				
								</View>
								{tabView}

							</View>
						}
					</View>
				</ScrollView>
			</View>
		)
	}



	displaydata() {
		tabView = [];
		for (let i = 0; i < this.data.length; i++) {
			console.log("ici")
			{
				tabView.push(
					<View key={i}>
						<View style={componentstyle.tab}>
							<View style={componentstyle.tabcontent}>
								<Text style={textstyle.text}>{Moment(this.data[i].date).format('DD.MM.YYYY')}</Text>
							</View>
							<View style={componentstyle.tabcontent}>
								<Text style={textstyle.text}>{this.data[i].appareil}</Text>
							</View>
							<View style={componentstyle.tabcontent}>
								<Text style={textstyle.text}>{this.data[i].version}</Text>
							</View>
							<View style={componentstyle.tabcontent}>
								<Text style={textstyle.text}>{this.data[i].medecin}</Text>
							</View>
							<View style={componentstyle.tabcontent}>
								<TouchableOpacity onPress={() => download()}>
									<Text style={textstyle.link}>télécharger</Text>
								</TouchableOpacity>
							</View>
						</View>
					</View>
				)
			}
		}
		this.forceUpdate()
	}

	download(){
		console.log("télécharger")
	}
	
	displayImage() {
		imagesort = []
		
		for (let i = 0; i <= this.data.length; i++) { // this.data.length
			if(this.data[i] !== undefined){
				tabViewLocal = []
				for (let j = 0; j < this.data[i].nbrImg ; j++) { // this.data[i].nbrImg
					if(this.data[i].imageInfos[j] !== undefined)
					{
						if(this.data[i].imageInfos[j].iiWavelength !== undefined){
							console.log("PRINT !!!!!!!!!!!! IMG ")
							tabViewLocal.push(
								<View style={componentstyle.tabcontent} key={10+((i+1)*10)+((i+1)*(j+1))}>
									<TouchableOpacity onPress={() => console.log(10+((i+1)*10)+((i+1)*(j+1)))}>
										<Text style={textstyle.link}>{"Img : " + this.data[i].imageInfos[j].iiWavelength+ " nm / i : " + i + "j : "+ j}</Text>
										<View style={styles.imagecontainer}>	
											<Image style={{ resizeMode: "cover", height: 200, width: 200 }}
												source={{ uri: ("https://portal.coskin.ch/coskinData/images/" + this.data[i].idclient + "/"  + this.data[i].path.split("/").pop() + "/"+ this.data[i].imageInfos[j].iiFileName )
												}}/>
												{console.log(10+((i+1)*10)+((i+1)*(j+1)))}{console.log("PUSH VALUE ")}{console.log("https://portal.coskin.ch/coskinData/images/" + this.data[i].idclient + "/"  + this.data[i].path.split("/").pop() + "/"+ this.data[i].imageInfos[j].iiFileName)}
										</View>
									</TouchableOpacity>
								</View>
							)

						}
					}
				}
				if(this.data[i].imageInfos[0] !== undefined){
					if(this.data[i].imageInfos[0].iiWavelength !== undefined){
						console.log(" PRINT !!!!!!!!!!!! IMG ")
						imagesort.push(
							<View key={i}>
								<View style={componentstyle.tab}>
									{tabViewLocal}
								</View>
							</View>
						)
					}
				}
			}
		}
		this.forceUpdate()
	}
	
}


export default Anaylse