import React from 'react';
import { Text, View, TouchableOpacity, ScrollView } from "react-native"
import Header from '../Component/Header';
import { componentstyle, textstyle } from "../assets/Style";
import { Picker } from '@react-native-picker/picker';
import { ButtonStandard } from '../Component/Button';
import AsyncStorage from "@react-native-async-storage/async-storage";
import Moment from 'moment';

var datesort = []
var imagesort = []
class Patient extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			type:"date"
		}
	}
	data = []
	imageinfoRec = []

	addPatient(){
		this.props.navigation.navigate('AddPatient')
	}


	componentDidMount() {

			AsyncStorage.getItem('imageinfos')
			.then(imageinfos => {
				// Prend toutes les analyses du client ('id, path, nbimage, timestanp')
				imageinfos = JSON.parse(imageinfos).imageinfos
				this.imageinfoRec = imageinfos
				console.log('Img Info Rec init')
				console.log(this.imageinfoRec)
			})
			AsyncStorage.getItem('analyses')
			.then(analyses => {
				console.log(analyses)
				analyses = JSON.parse(analyses).analyses
				console.log(analyses)
				this.data=analyses
				console.log('patient init')
				console.log(this.data)
				this.displayclient()
			})
			
	}


	render() {
		this.displayImage()
		return (
			<View style={componentstyle.container}>
				<Header />
				<ScrollView>
					<View style={componentstyle.textcontainer}>
						<Text style={textstyle.titre2}>Analyse</Text>
						<View style={componentstyle.standardView}>

						<ButtonStandard
							onPress={() => {
								this.addPatient()
							}}>
							Ajouter un Patient
						</ButtonStandard>

						</View>
                        <View style={componentstyle.textcontainer}>

							<Picker
								style={componentstyle.Picker}
								selectedValue={this.state.type}
								onValueChange={(value) => {
									this.setState({ type: value })
								}}
							>
								<Picker.Item label="Date" value="Date" />
								<Picker.Item label="Image" value="Image" />
							</Picker>
						</View>
						{this.state.type == "Image" ?
							<View>
								{imagesort}
							</View>
							:
							<View>
								{datesort}
							</View>
						}

						
						<View style={componentstyle.tab}>
							<Text style={textstyle.textbold}>Si une analyse n'apparait pas vous devez synchroniser votre appareil dans un premier temps puis cliquer sur le bouton ci-dessous</Text>
						</View>
						<ButtonStandard
							onPress={() => {
								console.log("synchroniser")
							}}>
							Synchroniser
					</ButtonStandard>




					</View>
				</ScrollView>
			</View>
		)
	}
	analyse(data,index,client){
		console.log("Test clients")
		console.log(client)
		console.log(index)
		console.log(this.imageinfoRec[index].imageinfosAll)
		console.log("fin Test clients")
		let note=Math.floor(Math.random() * 4) + 6
		AsyncStorage.removeItem('analyse2')
		AsyncStorage.setItem('analyse2', JSON.stringify({
			client:data[client].client,
			firstname:data[client].firstname,
			lastname:data[client].lastname,
			date:data[client].analyses[index].date,
			path:data[client].analyses[index].path,
			humidity:data[client].analyses[index].humidity,
			note:note,
			imageinfo:this.imageinfoRec[index].imageinfosAll
		}));
		this.props.navigation.navigate('RapportDate')
	}
	displaydata(client,length) {
		console.log(this.data)
			console.log("ici")
			let analysesdata=[]
			for(let i=0;i<length;i++){
				analysesdata.push(
					<View key={i}>
						<View style={componentstyle.tab}>
							<View style={componentstyle.tabcontent}>
								<Text style={textstyle.text}>{Moment(this.data[client].analyses[i].date).format('DD.MM.YYYY')}</Text>
							</View>
							<View style={componentstyle.tabcontent}>
								<Text style={textstyle.text}>{this.data[client].analyses[i].systeminfo}</Text>
							</View>
							<View style={componentstyle.tabcontent}>
								<Text style={textstyle.text}>{this.data[client].analyses[i].version}</Text>
							</View>
							<View style={componentstyle.tabcontent}>
								<TouchableOpacity onPress={() => this.analyse(this.data,i,client)}>
									<Text style={textstyle.link}>Rapport</Text>
								</TouchableOpacity>
							</View>
						</View>
					</View>
				)
			}
				return analysesdata
			
		}
	displayclient() {
		for (let i = 0; i < this.data.length; i++) {
			console.log("ici")
			console.log(this.data[i].client)
			{
				datesort.push(
					<View key={i}>
						<View style={componentstyle.tab}>
							<Text style={textstyle.soustitre}>{this.data[i].firstname} {this.data[i].lastname}</Text>
						</View>
						{this.displaydata(i,this.data[i].analyses.length)}
					</View>
				)
			}
		}
		this.forceUpdate()
	}
	displayImage() {
		imagesort = []
		for (let i = 0; i < this.data.length; i++) {
			{
				imagesort.push(
					<View key={i}>
						<View style={componentstyle.tab}>
							<Text style={textstyle.soustitre}>{this.data[i].client}</Text>
						</View>
						<View style={componentstyle.tab}>
							<View style={componentstyle.tabcontent}>
								<TouchableOpacity onPress={() => this.props.navigation.navigate('RapportImage')}>
									<Text style={textstyle.link}>UV</Text>
								</TouchableOpacity>
							</View>
							<View style={componentstyle.tabcontent}>
								<TouchableOpacity onPress={() => this.props.navigation.navigate('RapportImage')}>
									<Text style={textstyle.link}>Bleu</Text>
								</TouchableOpacity>
							</View>
							<View style={componentstyle.tabcontent}>
								<TouchableOpacity onPress={() => this.props.navigation.navigate('RapportImage')}>
									<Text style={textstyle.link}>Vert</Text>
								</TouchableOpacity>
							</View>
						</View>
						<View style={componentstyle.tab}>
							<View style={componentstyle.tabcontent}>
								<TouchableOpacity onPress={() => this.props.navigation.navigate('RapportImage')}>
									<Text style={textstyle.link}>Orange</Text>
								</TouchableOpacity>
							</View>
							<View style={componentstyle.tabcontent}>
								<TouchableOpacity onPress={() => this.props.navigation.navigate('RapportImage')}>
									<Text style={textstyle.link}>IR</Text>
								</TouchableOpacity>
							</View>
							<View style={componentstyle.tabcontent}>
								<TouchableOpacity onPress={() => this.props.navigation.navigate('RapportImage')}>
									<Text style={textstyle.link}>Blanc</Text>
								</TouchableOpacity>
							</View>
						</View>
					</View>
				)
			}
		}
	}
}

export default Patient