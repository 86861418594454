import Header from "../Component/Header"
import React from "react"
import { Text, View, Image, ScrollView,TouchableOpacity } from "react-native";
import { componentstyle, textstyle } from "../assets/Style";
import { ButtonStandard } from "../Component/Button";
import { BigInputFormMutliple } from "../Component/Input";
import { Picker } from "@react-native-picker/picker";
var images= []
class RapportScreenImage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            type: "",
            led:"UV",
            client:"Raphael Buffet"
        }
        this.data = [{
            date: "12.01.2012",
            humidity: "20",
            note: "8"
        },
        {
            date: "12.01.2012",
            humidity: "20",
            note: "8"
        }
    ]
    }
    render() {
        this.displayImage();
        return (
            <View style={componentstyle.container}>
                <Header />
                <ScrollView>
                    <View style={componentstyle.form}>
                        <View style={componentstyle.textcontainer}>
                            <Text style={textstyle.soustitre}>{this.state.client}</Text>
                        </View>
                        <View style={componentstyle.textcontainer}>
                            <Text style={textstyle.soustitre}>Analyse {this.state.led}</Text>
                        </View>
                        <View style={componentstyle.textcontainer}>
                            <Picker
                                style={componentstyle.Picker}
                                selectedValue={this.state.type}
                                onValueChange={(value) => {
                                    this.setState({ type: value })
                                }}
                            >
                                <Picker.Item label="Visage" value="Visage" />
                                <Picker.Item label="Bras" value="Bras" />
                            </Picker>
                        </View>
                    </View>
                    <View style={componentstyle.form}>
                        <Text style={textstyle.soustitre}>Images</Text>
                    </View>
                    <View style={componentstyle.form}>
                    {images}
                    </View>
                    <View style={componentstyle.form}>
                        <Text style={textstyle.soustitre}>Commentaire d'amélioration</Text>
                    </View>
                    <BigInputFormMutliple />
                    <ButtonStandard
                        onPress={() => {
                            this.props.navigation.goBack()
                        }}>
                        Enregistrer
                </ButtonStandard>
                </ScrollView>
            </View>
        )
    }
    displayImage() {	
        images = []
        for (let i = 0; i < this.data.length; i++) {
            console.log("ici")
            {
                images.push(
                    <View style={componentstyle.imagecontainer}>
                            <Image
                                style={componentstyle.image}
                                source={require('../assets/Image/example.jpg')} />
                            <Text style={textstyle.textbold}>{this.data[i].date}</Text>
                            <Text style={textstyle.textbold}>Note : {this.data[i].note}/10</Text>
                        </View>
                )
            }
        }
    }
}


export default RapportScreenImage