import React from 'react';
import { createAppContainer } from 'react-navigation';
import {connect} from 'react-redux';
import {login, logout} from "../Store/User/UsersActions";
import AsyncStorage from "@react-native-async-storage/async-storage";
import LoadingScreen from "../Commun/LoadingScreen";
import LoginScreen from "../Commun/Login/LoginScreen";
import AppDrawerNavigatorClient from "../Client/AppDrawerNavigatorClient";
import AppDrawerNavigatorUser from "../User/AppDrawerNavigatorUser";
import {View} from 'react-native';

const AppContainerClient = createAppContainer(AppDrawerNavigatorClient);
const AppContainerUser = createAppContainer(AppDrawerNavigatorUser);

class Coskin extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading:false,
            client:true
        }
    }
    componentDidMount() {
        AsyncStorage.getItem('user')
            .then(user =>{
                console.log(user)
                if(user != null){
                    this.props.reduxUpdateUser(JSON.parse(user));
                }
                this.setState({
                    loading:false
                });
            });
    }

    render() {
        return (
            this.rendercurrentstate()
        )
    }

    rendercurrentstate() {
        if(this.state.loading){
            return (<LoadingScreen/>)
        }
        else  if (this.props.user.isLogged) {
            return (
                this.props.user.isClient ?
                    <View style={{flex:1}}><AppContainerClient/></View> :
                    <View style={{flex:1}}><AppContainerUser/></View>
            )
        }
        console.log("login")
        return (
            <LoginScreen />
        )
    }
}

// Récupération des variables de Redux
const mapStateToProps = (state) => {
    return{
        user: state.user,
    }}

// Mise à jour des variables de rédux
const mapDispatchToProps = (dispatch) =>{
    return{
        reduxUpdateUser:(user) => dispatch(login(user)),
        reduxLogOut:()=>dispatch(logout()),
    }}

export default connect(mapStateToProps,mapDispatchToProps)(Coskin)