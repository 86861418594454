import React from 'react';
import { Text, View, ScrollView } from "react-native";
import { componentstyle, textstyle } from "../assets/Style";
import { ButtonRetour, ButtonBig } from '../Component/Button';
import { InputForm } from '../Component/Input'
import { Picker } from '@react-native-picker/picker';
import { Pickerday, Pickermonth, Pickeryear } from '../Component/Picker';
import Header from '../Component/Header';

class Inscription extends React.Component {
    state = {
        type: "",
        utilisateur: "",
        motDePasse: "",
        email: "",
        naissance: "",
        jour: "",
        mois: "",
        annee: "",
        estheticien: ""
    }
    render() {
        return (
            <View style={componentstyle.container}>
                <Header />
                <ScrollView>
                    <View style={componentstyle.textcontainer}>
                        <View style={componentstyle.form}>
                            <View style={componentstyle.formelement}>
                                <Text style={textstyle.text}>Type de compte :</Text>
                            </View>
                            <View style={componentstyle.formelement}>
                                <Picker
                                    style={componentstyle.Picker}
                                    selectedValue={this.state.type}
                                    onValueChange={(value) => {
                                        this.setState({ type: value })
                                    }}
                                >
                                    <Picker.Item label="Esthéticien" value="Esthéticien (-ne)" />
                                    <Picker.Item label="Client" value="Client" />
                                </Picker>
                            </View>
                        </View>
                        <View style={componentstyle.form}>
                            <View style={componentstyle.formelement}>
                                <Text style={textstyle.text}>Nom d'utilisateur :</Text>
                            </View>
                            <InputForm
                                onChangeText={(utilisateur) => this.setState({ utilisateur })}
                                value={this.state.utilisateur}
                                secure={false}
                            />
                        </View>
                        <View style={componentstyle.form}>
                            <View style={componentstyle.formelement}>
                                <Text style={textstyle.text}>Mot de passe :</Text>
                            </View>
                            <InputForm
                                onChangeText={(motDePasse) => this.setState({ motDePasse })}
                                value={this.state.motDePasse}
                                secure={true}
                            />
                        </View>
                        <View style={componentstyle.form}>
                            <View style={componentstyle.formelement}>
                                <Text style={textstyle.text}>E-mail :</Text>
                            </View>
                            <InputForm
                                onChangeText={(email) => this.setState({ email })}
                                value={this.state.email}
                                secure={false}
                            />
                        </View>
                        {this.state.type == "Client" ?
                            <View>
                                <View style={componentstyle.form}>
                                    <View style={componentstyle.formelement}>
                                        <Text style={textstyle.text}>Date de naissance :</Text>
                                    </View>
                                    <View style={componentstyle.formelement}>
                                        <Pickerday
                                            style={componentstyle.Picker}
                                            selectedValue={this.state.jour}
                                            onValueChange={(value) => {
                                                this.setState({ jour: value })
                                            }}></Pickerday>

                                        <Pickermonth
                                            style={componentstyle.Picker}
                                            selectedValue={this.state.mois}
                                            onValueChange={(value) => {
                                                this.setState({ mois: value })
                                            }}></Pickermonth>

                                        <Pickeryear
                                            style={componentstyle.Picker}
                                            selectedValue={this.state.annee}
                                            onValueChange={(value) => {
                                                this.setState({ annee: value })
                                            }}
                                        ></Pickeryear>
                                    </View>
                                </View>
                                <View style={componentstyle.form}>
                                    <View style={componentstyle.formelement}>
                                        <Text style={textstyle.text}>Esthéticien référent :</Text>
                                    </View>
                                    <View style={componentstyle.formelement}>
                                        <Picker
                                            style={componentstyle.Picker}
                                            selectedValue={this.state.estheticien}
                                            onValueChange={(value) => {
                                                this.setState({ estheticien: value })
                                            }}
                                        >
                                            <Picker.Item label="Inconnu" value="Inconn" />
                                            <Picker.Item label="Boniface Gifo" value="Boniface" />
                                            <Picker.Item label="Muriel Michaud" value="Muriel" />

                                        </Picker>
                                    </View>
                                </View>
                            </View> :
                            <View></View>}
                    </View>
                    <ButtonRetour onPress={() => this.props.navigation.goBack()}>Retour</ButtonRetour>
                    <ButtonBig onPress={() => this.props.navigation.navigate('Inscription')}>S'inscrire</ButtonBig>
                </ScrollView>
            </View>
        )
    }
}

export default Inscription