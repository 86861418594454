import React from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import Appareil from "./AppareilScreen";
import EditAppareil from "./EditAppareilScreen";

const StackAppareil = createStackNavigator();
export default function Navigator(){
  return (
    <NavigationContainer>
        <StackAppareil.Navigator initialRouteName="Appareil">
        <StackAppareil.Screen name="Appareil" component={Appareil} options={{
                headerShown: false,                
            }} />
            {console.log("Appareil Navigator OK")}
            <StackAppareil.Screen name="EditAppareil" component={EditAppareil} options={{
                headerShown: false              
            }} />
        </StackAppareil.Navigator>
    </NavigationContainer>
);
}