import React from "react"
import { View,Text } from "react-native"
import {componentstyle,textstyle} from "../assets/Style";


const Header = () => {
    return(
        <View>
        <Text style={textstyle.titre}>Coskin</Text>
        </View>
    )
}
export default Header

