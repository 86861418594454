import React from 'react';
import { Text, View, TouchableOpacity, ScrollView, Alert } from "react-native";
import { componentstyle, textstyle } from "../assets/Style";
import { ButtonRetour, ButtonBig } from '../Component/Button';
import { showMessage } from "react-native-flash-message";
import { InputForm } from '../Component/Input'
import { Picker } from '@react-native-picker/picker'
import config from '../config'
import axios from 'axios';
import Header from '../Component/Header';
import { connect } from "react-redux";
import { login } from "../Store/User/UsersActions";
import AsyncStorage from "@react-native-async-storage/async-storage";


class Connexion extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            type: "users",
            utilisateur: "",    
            motDePasse: "",
            error: false
        }
    }
    loginClient(res) {
        console.log("clients")
        this.props.reduxUpdateUser({
            id: res.data.id,
            userId: res.data.userid,
            firstname: res.data.firstname,
            lastname: res.data.lastname,
            birthday: res.data.birthday,
            type: "clients",
            isClient: true,
            isLogged: true
        })

        // Set user
        AsyncStorage.setItem('user', JSON.stringify({
            id: res.data.id,
            userId: res.data.userid,
            firstname: res.data.firstname,
            lastname: res.data.lastname,
            birthday: res.data.birthday,
            type: "clients",
            isClient: true,
            isLogged: true
        }));

        // Get ALL user info
        axios.get(config.backEndURL + config.backEndApiURL + "users/" + res.data.userid)
            .then((res) => {
                AsyncStorage.setItem('medecin', JSON.stringify({
                    id: res.data.id,
                    nom: res.data.username,
                    mail: res.data.email,
                    telephone: res.data.phoneNumber
                }))
            })
            
            
        // Get info measurement
        axios.get(config.backEndURL + config.backEndApiURL + "measurements/" + res.data.id)
            .then((res) => {
                console.log(res.data)
                if (res.data != undefined) {
                    let analyses = []
                    let imageinfos = []
                    
                    for (let i = 0; i < res.data.length; i++) {
                        axios.get(config.backEndURL + config.backEndApiURL + "systeminfos/" + res.data[i].idSystemInfo)
                            .then((result) => {
                                analyses.push({
                                    id: res.data[i].id,
                                    path: res.data[i].folderPath,
                                    nbimage: res.data[i].nbImage,
                                    timestamp: res.data[i].timestamp,
                                    humidity: res.data[i].humidity,
                                    systeminfo: res.data[i].idSystemInfo,
                                    version: result.data.softwareVersion,
                                })
                            })
                        
                        axios.get(config.backEndURL + config.backEndApiURL + "imageinfos/" + res.data[i].id)
                            .then((resu) => {
                                let subimageinfos = []
                                for(let j=0;j<res.data[i].nbImage;j++){	
                                    subimageinfos.push({      
                                        iiFileName:resu.data.result[j].filename,
                                        iiWavelength:resu.data.result[j].waveLength})
                                }
                                imageinfos.push({
                                    imageinfosAll:subimageinfos
                                })
                            })
                        
                    }
                    setTimeout(() => {
                        AsyncStorage.setItem('analyses', JSON.stringify({
                            analyses: analyses
                        }))
                        AsyncStorage.setItem('imageinfos', JSON.stringify({
                            imageinfos: imageinfos
                        }))
                    }, 1000)
                }
            })
    }
    loginMedecin(res) {
        console.log("user")
        this.props.reduxUpdateUser({
            id: res.data.id,
            username: res.data.username,
            email: res.data.email,
            phoneNumber: res.data.téléphone,
            type: "users",
            isClient: false,
            isLogged: true
        })
        AsyncStorage.setItem('user', JSON.stringify({
            id: res.data.id,
            username: res.data.username,
            email: res.data.email,
            phoneNumber: res.data.téléphone,
            type: "users",
            isClient: false,
            isLogged: true
        }));
        console.log("Connexion before Devices")
        console.log(res)
        console.log(res.data.id)
        axios.get(config.backEndURL + config.backEndApiURL + "devices/" + res.data.id)
            .then((res) => {
                console.log(res.data)
                if (res.data.status == true) {
                    let devices = []
                    for (let i = 0; i < res.data.data.length; i++) {
                        console.log("Connexion Device push")
                        devices.push({
                            id: res.data.data[i].idDevice,
                            version: res.data.data[i].version,
                            date: res.data.data[i].date
                        })
                    }
                    AsyncStorage.setItem('devices', JSON.stringify({
                        devices: devices
                    }));
                }

            })
        axios.get(config.backEndURL + config.backEndApiURL + "clients/" + res.data.id)
            .then((res) => {
                console.log(res.data)
                if (res.data[0] != undefined) {
                    let clients = []
                    let allanalyses = []
                    let imageinfos = []
                    for (let i = 0; i < res.data.length; i++) {
                        clients.push({
                            id: res.data[i].id,
                            firstname: res.data[i].firstname,
                            lastname: res.data[i].lastname
                        })
                        axios.get(config.backEndURL + config.backEndApiURL + "measurements/" + res.data[i].id)
                            .then((resu) => {
                                if (resu.data != undefined) {
                                    let analyses=[]
                                    for (let i = 0; i < resu.data.length; i++) {
                                        console.log(resu.data[i])
                                        axios.get(config.backEndURL + config.backEndApiURL + "systeminfos/" + resu.data[i].idSystemInfo)
                                            .then((result) => {
                                                analyses.push({
                                                    id: resu.data[i].id,
                                                    path: resu.data[i].folderPath,
                                                    nbimage: resu.data[i].nbImage,
                                                    date: resu.data[i].timestamp,
                                                    humidity: resu.data[i].humidity,
                                                    systeminfo: resu.data[i].idSystemInfo,
                                                    version: result.data.softwareVersion,
                                                    idclient : result.data.idclient
                                                })
                                            })
                                        axios.get(config.backEndURL + config.backEndApiURL + "imageinfos/" + resu.data[i].id)
                                        .then((resul) => {
                                            let subimageinfos = []
                                            console.log(resu)
                                            console.log(resul)
                                            for(let j=0;j<resu.data[i].nbImage;j++){	
                                                subimageinfos.push({      
                                                    iiFileName:resul.data.result[j].filename,
                                                    iiWavelength:resul.data.result[j].waveLength})
                                            }
                                            imageinfos.push({
                                                imageinfosAll:subimageinfos
                                                })
                                            })

                                    }
                                    
                                    setTimeout(() => {
                                        allanalyses.push({
                                            clients:res.data[i].id,
                                            firstname: res.data[i].firstname,
                                            lastname: res.data[i].lastname,
                                            analyses: analyses
                                        })
                                        console.log(allanalyses)
                                        
                                    }, 2000)
                                }
                            })
                    }
                    AsyncStorage.setItem('clients', JSON.stringify({
                        clients: clients
                    }));
                    
                    tTimeout(()=>{
                        console.log("go")
                        AsyncStorage.setItem('analyses', JSON.stringify({
                            analyses: allanalyses
                        }))
                        AsyncStorage.setItem('imageinfos', JSON.stringify({
                            imageinfos: imageinfos
                        }))
                    },6000)
                    
                }
            })
    }
    login() {
        AsyncStorage.removeItem('user');
		AsyncStorage.removeItem('medecin');
		AsyncStorage.removeItem('clients');
		AsyncStorage.removeItem('devices');
		AsyncStorage.removeItem('analyses');
        console.log("login")
        console.log(config.backEndURL + config.backEndApiURL + this.state.type + '/login')
        axios.post(config.backEndURL + config.backEndApiURL + this.state.type + '/login', {
            username: this.state.utilisateur,
            password: this.state.motDePasse
        })
            .then((res) => {
                console.log(res.data)
                if (res.data.status != undefined) {
                    this.setState({ error: true })
                    console.log("email")
                }
                else {
                    if (this.state.type == "clients") {
                        this.loginClient(res)
                    }
                    else {
                        this.loginMedecin(res)
                    }

                }
            })
    }
    render() {
        return (
            <View style={componentstyle.container}>
                <Header />
                <ScrollView>
                    <View style={componentstyle.textcontainer}>
                        <View style={componentstyle.form}>
                            <View style={componentstyle.formelement}>
                                <Text style={textstyle.text}>Type :</Text>
                            </View>
                            <View style={componentstyle.formelement}>
                                <Picker
                                    style={componentstyle.Picker}
                                    selectedValue={this.state.type}
                                    onValueChange={(value) => {
                                        this.setState({ type: value })
                                    }}
                                >
                                    <Picker.Item label="Esthéticien (-ne)" value="users" />
                                    <Picker.Item label="Client (-e)" value="clients" />
                                </Picker>
                            </View>
                        </View>
                        <View style={componentstyle.form}>
                            <View style={componentstyle.formelement}>
                                <Text style={textstyle.text}>Nom d'utilisateur :</Text>
                            </View>
                            <InputForm
                                onChangeText={(utilisateur) => this.setState({ utilisateur })}
                                value={this.state.utilisateur}
                                secure={false}
                            />
                        </View>
                        <View style={componentstyle.form}>
                            <View style={componentstyle.formelement}>
                                <Text style={textstyle.text}>Mot de passe :</Text>
                            </View>
                            <InputForm
                                onChangeText={(motDePasse) => this.setState({ motDePasse })}
                                value={this.state.motDePasse}
                                secure={true}
                            />
                        </View>
                        <View style={componentstyle.form}>
                            <View style={componentstyle.formelement}></View>
                            <View style={componentstyle.formelement}>
                                <TouchableOpacity onPress={() => this.props.navigation.navigate("MotDePasse")}>
                                    <Text style={{ textAlign: "left", color: 'red', fontSize: 28 }}>Mot de passe oublié ?</Text>
                                </TouchableOpacity>
                            </View>
                        </View>
                    </View>
                    {this.state.error ? <View style={componentstyle.textcontainer}>
                        <Text style={{ color: 'red', fontSize: 32 }}>E-mail ou mot de passe incorrect</Text>
                    </View> :
                        <View></View>
                    }
                    <ButtonRetour onPress={() => this.props.navigation.goBack()}>Retour</ButtonRetour>
                    <ButtonBig onPress={() => this.login()}>Se Connecter</ButtonBig>
                </ScrollView>
            </View>
        )
    }
}

// Récupération des variables de Redux
const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

// Mise à jour des variables de rédux
const mapDispatchToProps = (dispatch) => {
    return {
        reduxUpdateUser: (user) => dispatch(login(user))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Connexion);