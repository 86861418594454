import React from 'react';
import { Text, View, ScrollView } from "react-native"
import Header from '../Component/Header';
import { componentstyle, textstyle } from "../assets/Style";
import { InputForm, InputFormMutliple } from '../Component/Input';

class Contact extends React.Component {
	state = {
		mailCoskin: "info@coskin.ch",
		telephoneCoskin: "079 467 70 72",
	}
	render() {
		return (
				<View style={componentstyle.container}>
				<Header/>
				<ScrollView>
					<View style={componentstyle.textcontainer}>
						<Text style={textstyle.titre2}>Contact</Text>
						<View style={componentstyle.form}>
							<Text style={textstyle.textbold}>En cas de problème ou de questions vous pouvez nous contacter</Text>
						</View>
						<View style={componentstyle.form}>
							<View style={componentstyle.formelement}>
								<Text style={textstyle.text}>par e-mail :</Text>
							</View>
							<View style={componentstyle.formelementlittle}>
								<Text style={textstyle.link}>{this.state.mailCoskin}</Text>
							</View>
						</View>
						<View style={componentstyle.form}>
							<View style={componentstyle.formelement}>
								<Text style={textstyle.text}>par téléphone :</Text>
							</View>
							<View style={componentstyle.formelementlittle}>
								<Text style={textstyle.textbold}>{this.state.telephoneCoskin}</Text>
							</View>
						</View>
					</View>
					</ScrollView>
				</View>
		)
	}
}

export default Contact