import {createBottomTabNavigator} from "react-navigation-tabs";
import ProfilScreen from "./ProfilScreen.js";
import ContactScreen from "./ContactScreen";
import AppareilScreen from "./AppareilNavigator";
import PatientScreen from "./PatientNavigator";
import DeconnexionScreen from "../Commun/DeconnexionScreen";
import { APP_COLORS } from "../assets/Color.js";

const AppDrawerNavigatorUser = createBottomTabNavigator({
    Profil: {
        screen: ProfilScreen,
        navigationOptions: {
            tabBarOptions: {
                style: {
                   backgroundColor: APP_COLORS.button,
                },
                labelStyle: {
                    fontSize: 28,
                    margin: 0,
                    padding: 0
                  }
              }
        },
        path: '/',
    },
    Patient: {
        screen: PatientScreen,
        navigationOptions: {
            tabBarOptions: {
                style: {
                   backgroundColor: APP_COLORS.button,
                },
                labelStyle: {
                    fontSize: 28,
                    margin: 0,
                    padding: 0
                  }
              }
        },
        path: '/',
    },
    Appareils: {
        screen: AppareilScreen,
        navigationOptions: {
            tabBarOptions: {
                style: {
                   backgroundColor: APP_COLORS.button,
                },
                labelStyle: {
                    fontSize: 28,
                    margin: 0,
                    padding: 0
                  }
              }
        },
        path: '/',
    },
    Contact: {
        screen: ContactScreen,
        navigationOptions: {
            tabBarOptions: {
                style: {
                   backgroundColor: APP_COLORS.button,
                },
                labelStyle: {
                    fontSize: 28,
                    margin: 0,
                    padding: 0
                  }
              }
        },
        path: '/',
    },
   
    Parametre:{
        screen: DeconnexionScreen,
        navigationOptions: {
            tabBarOptions: {
                style: {
                   backgroundColor: APP_COLORS.button,
                },
                labelStyle: {
                    fontSize: 28,
                    margin: 0,
                    padding: 0
                  }
              }
        },
        path: '/',
    },
});

export default AppDrawerNavigatorUser;