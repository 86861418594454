import React from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import Patient from "./PatientScreen";
import RapportDate from "./RapportScreenDate";
import RapportImage from "./RapportScreenImage";
import DetailsImage from "./DetailsImage"
import AddPatient from "./AddScreenPatient"
const StackPatient = createStackNavigator();
export default function Navigator(){
  return (
    <NavigationContainer>
        <StackPatient.Navigator initialRouteName="Patient">
            <StackPatient.Screen name="Patient" component={Patient} options={{
                headerShown: false,
            }} />
            {console.log("Patient Navigator OK")}
            <StackPatient.Screen name="RapportDate" component={RapportDate} options={{
                headerShown: false,
            }} />
            <StackPatient.Screen name="RapportImage" component={RapportImage} options={{
                headerShown: false
            }} />
             <StackPatient.Screen name="DetailsImage" component={DetailsImage} options={{
                headerShown: false
            }} />
              <StackPatient.Screen name="AddPatient" component={AddPatient} options={{
                headerShown: false,
            }} />
        </StackPatient.Navigator>
    </NavigationContainer>
);
}