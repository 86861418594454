import React from "react";
import {View,Text} from "react-native";
import ProfilNavigator from "../../Login/LoginNavigator";

class LoginScreen extends React.Component {

    render() {
        return (
            <View style={{flex: 1, backgroundColor: "#00FF00"}}>
                <ProfilNavigator/>
            </View>
        )
    }
}

export default LoginScreen