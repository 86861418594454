import React from "react";
import {View} from "react-native";

class LoadingScreen extends React.Component {

    render() {
        return (
            <View style={{flex: 1, backgroundColor: "#00FF00"}}>
            </View>
        )
    }
}

export default LoadingScreen