import Header from "../Component/Header"
import React from "react"
import { Text, View} from "react-native";
import { componentstyle, textstyle } from "../assets/Style";
import { ButtonStandard } from "../Component/Button";
import { InputForm } from '../Component/Input';
import axios from 'axios';
import config from '../config'

class AddScreenPatient extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            type: "",
        }
    }

    state = {
        textIDstate: "123"
    }
    
    switchEdit(){
        console.log("SWITCH EDIT")
        this.state.edit = !this.state.edit
        this.forceUpdate()
    }

    addPatientById(){
        //1. contrôle que le patient existe
        axios.get(config.backEndURL + config.backEndApiURL + "clients/" + this.state.textIDstate)
        .then((res) => {
            console.log(res);
            if (res.data[0].id != undefined){
                console.log(res.data[0].firstname)
                console.log("You can EDIT the USER name for the client")
            }
        })  
        
        //2. put faire la fonctione qui edit le client pour ajouter au client la valeur de l'utilisateur

        this.state.edit = !this.state.edit
        this.forceUpdate()
    }


    componentDidMount(){
        setTimeout(() => {
            setTimeout(() => {
                this.setState({
                    textIDstate : "123"
                })
            }, 600)
        },800)
    }

    render() {
        return (
            <View style={componentstyle.container}>
                <Header />

                <View style={componentstyle.textcontainer}>
                    <Text style={textstyle.titre2}>Ajouter un patient par l'ID</Text>
                    
                    </View>
                        <View style={componentstyle.textcontainer}>

                    {!this.state.edit ?
                        <View style={componentstyle.formelementlittle}>
                            <Text style={textstyle.text}>{this.state.textIDstate}</Text>
                        </View>
                        :
                        <InputForm
                            onChangeText={(textIDstate) => this.setState({ textIDstate })}
                            value={this.state.textIDstate}
                            secure={false}
                        />
                    }
                        {!this.state.edit ?
                            <View>
                                <ButtonStandard onPress={() => { this.switchEdit() }}> Edit ID</ButtonStandard>
                            </View>
                            :
                            <View>
                                <ButtonStandard onPress={() => { this.addPatientById()}}> Sauvegarder </ButtonStandard>
                            </View>}
                </View>


                <ButtonStandard
                    onPress={() => {
                        this.props.navigation.goBack()
                    }}>
                    Retour
                </ButtonStandard>

            </View>
        
        )
    }
}

export default AddScreenPatient
