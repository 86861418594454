export default {
    apiKey: "AIzaSyCjepLo2xY0bhqWAKhCUyyoOiq42csIqtg",
    projectId: "tandem-94588",
    storageBucket: "tandem-94588.appspot.com",
    messagingSenderId: "419987512536",
    appId: "1:419987512536:web:f305e344d729c87ae78bad",
    measurementId: "G-52QBGVRMV1",
    backEndURL : 'https://coskin-server.herokuapp.com',
    //backEndURL : 'http://localhost',

    //backEndURL : 'https://uwfs.heig-vd.ch',
    //backEndURL : ('https:' == document.location.protocol ? 'https://' : 'http://') + 'uwfs.heig-vd.ch',
    backEndApiURL : '/api/v1/',
  };
