import React from 'react';
import { Text, View, Image, StyleSheet, ScrollView } from "react-native"
import Header from '../Component/Header';
import { componentstyle, textstyle } from "../assets/Style";
import { ButtonStandard } from '../Component/Button';
import { InputForm } from '../Component/Input';
import axios from 'axios';
import AsyncStorage from "@react-native-async-storage/async-storage";
import config from '../config';
import Moment from 'moment';
var id
var userid

const styles = StyleSheet.create({
	container: {
	  paddingTop: 50,
	},
	stretch: {
	  width: 50,
	  height: 200,
	  resizeMode: 'stretch',
	},
  });
class Profil extends React.Component {
	state = {
		nom: "exemple",
		prenom: "exemple",
		medecin: "exemple",
		nombreAnalyse: "exemple",
		derniereAnalyse: "exemple",
		edit: false,
		oldpassword: "",
		newpassword: "",
		newpassword2: "",
		passwordedit: false,
		buttontext: "Modifier mes données",
		error: ""
	}

	

	componentDidMount() {
		setTimeout(() => {
			let nom
			let prenom
			let medecinNom
			let analyse
			let derniereAnalyse

			AsyncStorage.getItem('user')
				.then(user => {
					user = JSON.parse(user)
					id = user.id
					userid = user.userId
					nom = user.lastname
					prenom = user.firstname
					console.log(user)
				})
			AsyncStorage.getItem('medecin')
				.then(medecin => {
					medecin = JSON.parse(medecin)
					medecinNom = medecin.nom
					console.log(medecin)
				})
			AsyncStorage.getItem('analyses')
				.then(analyses => {
					analyses = JSON.parse(analyses)
					analyse= analyses.analyses.length
					//derniereAnalyse =analyses.analyses[0].timestamp
					//console.log(Moment(analyses.analyses[0].timestamp).format('DD.MM.YYYY'))
					console.log(analyses)
				})
			setTimeout(() => {
				this.setState({
					nom: nom,
					prenom: prenom,
					medecin: medecinNom,
					nombreAnalyse: analyse,
					derniereAnalyse: derniereAnalyse
				})
			}, 2000)
		}, 2000)
	}
	save() {
		axios.post(config.backEndURL + config.backEndApiURL + 'clients/' + id, {
			firstname: this.state.prenom,
			lastname: this.state.nom,
		})
			.then((res) => {
				console.log(res)
			})
		AsyncStorage.setItem('user', JSON.stringify({
			id: id,
			userId: userid,
			firstname: this.state.prenom,
			lastname: this.state.nom,
			type: "client",
			isClient: true,
			isLogged: true,
		}));

		this.state.edit = !this.state.edit
		this.forceUpdate()
	}
	changepassword() {
		if (this.state.newpassword == this.state.newpassword2) {
			axios.post(config.backEndURL + config.backEndApiURL + 'client/password/' + id, {
				old: this.state.oldpassword,
				password: this.state.newpassword
			})
				.then((result) => {
					console.log("result")
					console.log(result.data.status)
					if (!result.data.status) {
						this.setState({ error: "ancien mot de passe incorrect" })
					}
					else {
						this.state.passwordedit = !this.state.passwordedit
						this.state.edit = false
						this.forceUpdate()
					}
				})
		}
		else {
			this.setState({ error: "les nouveau mot de passe doive correspondre" })
		}


	}
	switchEdit() {
		if (this.state.edit) {
			this.state.buttontext = "Modifier mes données"
		}
		else {
			this.state.buttontext = "Enregistrer"
		}
		this.state.edit = !this.state.edit
		this.forceUpdate()
		console.log(this.state.edit)
	}
	switchMotDePasse() {
		if (this.state.passwordedit) {
			this.state.buttontext = "Modifier mes données"
		}
		else {
			this.state.buttontext = "Enregistrer"
		}
		this.state.passwordedit = !this.state.passwordedit
		this.state.edit = false
		this.forceUpdate()
		console.log(this.state.passwordedit)
	}
	render() {
		return (
			<View style={componentstyle.container}>
				<Header />
				<ScrollView>
					<View style={componentstyle.textcontainer}>
						<Text style={textstyle.titre2}>Votre profil</Text>
						<View style={componentstyle.form}>
							<View style={componentstyle.formelement}>
								<Text style={textstyle.text}>Nom :</Text>
							</View>
							{!this.state.edit ?
								<View style={componentstyle.formelementlittle}>
									<Text style={textstyle.text}>{this.state.nom}</Text>
								</View>
								:
								<InputForm
									onChangeText={(nom) => this.setState({ nom })}
									value={this.state.nom}
									secure={false}
								/>
							}
						</View>
						<View style={componentstyle.form}>
							<View style={componentstyle.formelement}>
								<Text style={textstyle.text}>Prénom :</Text>
							</View>
							{!this.state.edit ?
								<View style={componentstyle.formelementlittle}>
									<Text style={textstyle.text}>{this.state.prenom}</Text>
								</View>
								:
								<InputForm
									onChangeText={(prenom) => this.setState({ prenom })}
									value={this.state.prenom}
									secure={false}
								/>
							}

						</View>
						<View style={componentstyle.form}>
							<View style={componentstyle.formelement}>
								<Text style={textstyle.text}>Médecin traitant :</Text>
							</View>
							<View style={componentstyle.formelementlittle}>
								<Text style={textstyle.text}>{this.state.medecin}</Text>
							</View>
						</View>
						<View style={componentstyle.form}>
							<View style={componentstyle.formelement}>
								<Text style={textstyle.text}>Nombre d'anaylse :</Text>
							</View>
							<View style={componentstyle.formelementlittle}>
								<Text style={textstyle.text}>{this.state.nombreAnalyse}</Text>
							</View>
						</View>
						<View style={componentstyle.form}>
							<View style={componentstyle.formelement}>
								<Text style={textstyle.text}>Dernière anaylse :</Text>
							</View>
							<View style={componentstyle.formelementlittle}>
								<Text style={textstyle.text}>{Moment(this.state.derniereAnalyse).format('DD.MM.YYYY')}</Text>
							</View>
						</View>
						{!this.state.passwordedit ? <View>
							{this.state.edit ?
								<View>
									<ButtonStandard onPress={() => { this.save() }}>Enregistrer</ButtonStandard>
									<ButtonStandard onPress={() => { this.switchMotDePasse() }}>Changer mon mot de passe</ButtonStandard>
								</View> :
								<View>
									<ButtonStandard onPress={() => { this.switchEdit() }}>Modifier mes données</ButtonStandard>
									<ButtonStandard onPress={() => { this.switchMotDePasse() }}>Changer mon mot de passe</ButtonStandard>
								</View>}
						</View>
							:
							<View>
								<View style={componentstyle.form}>
									<View style={componentstyle.formelement}>
										<Text style={textstyle.text}>Ancien mot de passe</Text>
									</View>
									<InputForm
										onChangeText={(oldpassword) => this.setState({ oldpassword })}
										value={this.state.oldpassword}
										secure={true}
									/>
								</View>
								<View style={componentstyle.form}>
									<View style={componentstyle.formelement}>
										<Text style={textstyle.text}>Nouveau mot de passe</Text>
									</View>
									<InputForm
										onChangeText={(newpassword) => this.setState({ newpassword })}
										value={this.state.newpassword}
										secure={true}
									/>
								</View>
								<View style={componentstyle.form}>
									<View style={componentstyle.formelement}>
										<Text style={textstyle.text}>Nouveau mot de passe</Text>
									</View>
									<InputForm
										onChangeText={(newpassword2) => this.setState({ newpassword2 })}
										value={this.state.newpassword2}
										secure={true}
									/>
								</View>
								<View style={componentstyle.textcontainer}>
									<Text style={{ color: 'red', fontSize: 32 }}>{this.state.error}</Text>
								</View>
								<ButtonStandard onPress={() => { this.changepassword() }}>Enregistrer</ButtonStandard>
							</View>
						}

					</View>
				</ScrollView>
			</View>
		)
	}
}

export default Profil