import Header from "../Component/Header"
import React from "react"
import { Text, View, Image, ScrollView,TouchableOpacity } from "react-native";
import { componentstyle, textstyle } from "../assets/Style";
import { ButtonStandard } from "../Component/Button";
import { BigInputFormMutliple } from "../Component/Input";
import { Picker } from "@react-native-picker/picker";
import AsyncStorage from "@react-native-async-storage/async-storage";
import Moment from 'moment';

var datas = []
class RapportScreenDate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            type: "",
        }
    }

    data={
        firstname : "Raphael",
        lastname: "Buffet",
        client: "123",
        date: "12.01.2012",
        humidity: "20",
        note: "8"
    }
    componentDidMount(){
        AsyncStorage.getItem('analyse2')
            .then(analyses3 => {
				analyses3 = JSON.parse(analyses3)
                console.log(this.data)
                this.data=analyses3
                if(this.data === undefined){
                    console.log('this.data undefined')
                }

                if(this.data.imageinfo === undefined){
                    console.log('this.data.imageinfo === undefined')
                }
                console.log(this.data)
                console.log(('https://portal.coskin.ch/coskinData/images/' + this.data.client + '/' + this.data.path.split("/").pop()+'/'+this.data.imageinfo[1].iiFileName))
                
			})

            setTimeout(() => {
				console.log("setTimeOut")
                this.displaydata()
			}, 1500)
    }
    detailImage(){
        this.props.navigation.navigate("DetailsImage")
    }
    render() {
        return (
            <View style={componentstyle.container}>
                <Header />
                <ScrollView>
                    <View style={componentstyle.form}>
                            <View style={componentstyle.textcontainer}>
                                <Text style={textstyle.soustitre}>{this.data.firstname} {this.data.lastname}</Text>
                            </View>
                            <View style={componentstyle.textcontainer}>
                                <Text style={textstyle.soustitre}>Analyse du {Moment(this.data.date).format('DD.MM.YYYY')}</Text>
                            </View>
                        </View>
                        <View style={componentstyle.form}>
                            <Text style={textstyle.soustitre}>Images</Text>
                        </View>
                    <View style={componentstyle.form}>

                    {datas}

                    </View>
                        <View style={componentstyle.form}>
                            <Text style={textstyle.soustitre}>Diagnostique générale</Text>
                        </View>
                        <BigInputFormMutliple />
                        <View style={componentstyle.form}>
                            <View style={componentstyle.textcontainer}>
                                <Text style={textstyle.textbold}>Humidité de la peau : {this.data.humidity}%</Text>
                            </View>
                            <View style={componentstyle.textcontainer}>
                                <Text style={textstyle.textbold}>Score générale : {this.data.note}/10</Text>
                            </View>
                    </View>
                    <ButtonStandard
                        onPress={() => {
                            this.props.navigation.goBack()
                        }}>
                        Enregistrer
                    </ButtonStandard>
                </ScrollView>
            </View>
        
        )
    }
    displaydata() {
        datas = [];
        console.log('this.data.nbrImg')
        console.log(this.data)
        for (let j = 0; j < this.data.imageinfo.length ; j++) {           
                datas.push(
                    <View key={1}>
                        <View style={componentstyle.imagecontainer}>
                        <TouchableOpacity
                        onPress={()=>{this.detailImage()}}>
                            {console.log(this.data.path)}
                            {console.log(this.data.imageinfo[0].iiFileName)}
                            <Image
                                style={componentstyle.image}
                                source={'https://portal.coskin.ch/coskinData/images/' + this.data.client + '/' + this.data.path.split("/").pop()+'/'+this.data.imageinfo[0].iiFileName} />
                            </TouchableOpacity>
                            <Text style={textstyle.textbold}>UV</Text>
                        </View>
                    </View>
                )
        }
        this.forceUpdate()
    }
}

export default RapportScreenDate
