import React from 'react';
import {View} from 'react-native';
import { TextInput } from 'react-native-gesture-handler';
import {textstyle,componentstyle} from  '../assets/Style'
const InputForm = ({onChangeText,value,secure}) => {
    return ( 
        <View style={componentstyle.formelementlittle}>
        <TextInput 
        style={textstyle.input}
        secureTextEntry={secure}
        onChangeText={onChangeText}
        value={value}>
        </TextInput>
    </View>
    )
}
const InputFormMutliple = ({onChangeText,value,secure}) => {
    return ( 
        <View style={componentstyle.formelementlittle}>
        <TextInput 
        style={textstyle.inputmultiple}
        secureTextEntry={secure}
        onChangeText={onChangeText}
        value={value}
        multiline={true}>
        </TextInput>
    </View>
    )
}
const BigInputFormMutliple = ({onChangeText,value,secure}) => {
    return ( 
        <View style={componentstyle.form}>
        <TextInput 
        style={textstyle.inputmultiplebig}
        secureTextEntry={secure}
        onChangeText={onChangeText}
        value={value}
        multiline={true}>
        </TextInput>
    </View>
    )
}
export {InputForm,InputFormMutliple,BigInputFormMutliple}