import React from 'react';
import { Text, View,ScrollView } from "react-native"
import { ButtonStandard } from '../Component/Button';
import Header from '../Component/Header';
import {connect} from "react-redux";
import {logout} from "../Store/User/UsersActions";
import AsyncStorage from "@react-native-async-storage/async-storage";

class Parametre extends React.Component {
	state = {
		
	}
	render() {
		return (
			<View>
				<Header/>
				<ScrollView>
					<ButtonStandard onPress={() => this._logout()}>Déconnexion</ButtonStandard>
				</ScrollView>
			</View>
		)
	}
	_logout() {
        AsyncStorage.removeItem('user');
		AsyncStorage.removeItem('medecin');
		AsyncStorage.removeItem('clients');
		AsyncStorage.removeItem('devices');
		AsyncStorage.removeItem('analyses');
        this.props.reduxLogOut();

    }
}
// Mise à jour des variables de rédux
const mapDispatchToProps = (dispatch) =>{
    return{
        reduxLogOut:()=>dispatch(logout()),
    }}

	export default connect(null,mapDispatchToProps)(Parametre)