import React from 'react';
import { Text, Image, View, ScrollView } from "react-native"
import Header from '../Component/Header';
import { componentstyle, textstyle } from "../assets/Style";
import AsyncStorage from "@react-native-async-storage/async-storage";

class Contact extends React.Component {
	state = {
		user: "exemple",
		mail: "exemple",
		telephone: "exemple",
		patient: "exemple",
		appareil: "exemple",
		courrier: "exemple",
		sujet: "",
		message: "",
		mailCoskin: "info@coskin.ch",
		telephoneCoskin: "079 467 70 72",
		username: "exemple"


	}
	componentDidMount() {
		AsyncStorage.getItem('medecin')
			.then(medecin => {
				medecin = JSON.parse(medecin)
				this.setState({username:medecin.nom,mail:medecin.mail,telephone:medecin.telephone})
				console.log(medecin)
			})
	}
	render() {
		return (

			<View style={componentstyle.container}>
				<Header />
				<ScrollView style={{ paddingBottom: 50 }}>
					<View style={componentstyle.textcontainer}>
						<Text style={textstyle.titre2}>Contact</Text>
						<Text style={textstyle.soustitre}>Votre esthéticien(-ne)</Text>
						<View style={componentstyle.form}>
							<Text style={textstyle.textbold}>En cas de conseil ou de questions vous pouvez contactez votre médecin</Text>
						</View>
						<View style={componentstyle.form}>
							<View style={componentstyle.formelement}>
								<Text style={textstyle.text}>nom :</Text>
							</View>
							<View style={componentstyle.formelementlittle}>
								<Text style={textstyle.text}>{this.state.username}</Text>
							</View>
						</View>
						<View style={componentstyle.form}>
							<View style={componentstyle.formelement}>
								<Text style={textstyle.text}>par e-mail :</Text>
							</View>
							<View style={componentstyle.formelementlittle}>
								<Text style={textstyle.link}>{this.state.mail}</Text>
							</View>
						</View>
						<View style={componentstyle.form}>
							<View style={componentstyle.formelement}>
								<Text style={textstyle.text}>par téléphone :</Text>
							</View>
							<View style={componentstyle.formelementlittle}>
								<Text style={textstyle.text}>{this.state.telephone}</Text>
							</View>
						</View>
						<Text style={textstyle.soustitre}>Coskin</Text>
						<View style={componentstyle.form}>
							<Text style={textstyle.textbold}>En cas de problème ou de questions vous pouvez nous contacter</Text>
						</View>
						<View style={componentstyle.form}>
							<View style={componentstyle.formelement}>
								<Text style={textstyle.text}>par e-mail :</Text>
							</View>
							<View style={componentstyle.formelementlittle}>
								<Text style={textstyle.link}>{this.state.mailCoskin}</Text>
							</View>
						</View>
						<View style={componentstyle.form}>
							<View style={componentstyle.formelement}>
								<Text style={textstyle.text}>par téléphone :</Text>
							</View>
							<View style={componentstyle.formelementlittle}>
								<Text style={textstyle.text}>{this.state.telephoneCoskin}</Text>
							</View>
						</View>
					</View>
				</ScrollView>
			</View>
		)
	}
}

export default Contact