import React from 'react';
import {Text,TouchableOpacity,View} from 'react-native';
import {textstyle,componentstyle} from  '../assets/Style'

const ButtonStandard = ({onPress,children}) => {
    return (
        <View style={componentstyle.buttoncontainer}>
        <TouchableOpacity onPress={onPress} style={componentstyle.button}>
            <Text style={textstyle.textbutton}> {children} </Text>
        </TouchableOpacity>
        </View>
    )
}

const ButtonBig= ({onPress,children}) => {
    return (
        <View style={componentstyle.buttoncontainer}>
        <TouchableOpacity onPress={onPress} style={componentstyle.bigButton}>
            <Text style={textstyle.textbutton}> {children} </Text>
        </TouchableOpacity>
        </View>
    )
}

const ButtonRetour = ({onPress,children}) => {
    return (
        <View style={componentstyle.buttoncontainer}>
        <TouchableOpacity onPress={onPress} style={componentstyle.littleButton}>
            <Text style={textstyle.textbutton}> {children} </Text>
        </TouchableOpacity>
        </View>
    )
}
export {ButtonStandard,ButtonRetour,ButtonBig}