import React from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import Accueil from "./Acceuil";
import Connexion from "./Connexion";
import Inscription from "./Inscription";
import MotDePasseOublie from "./MotDePasseOublie";
const StackLogin = createStackNavigator();
export default function Navigator(){
  return (
    <NavigationContainer>
        <StackLogin.Navigator initialRouteName="Accueil">
            <StackLogin.Screen name="Accueil" component={Accueil} options={{
                headerShown: false,
            }} />
            <StackLogin.Screen name="Connexion" component={Connexion} options={{
                headerShown: false,
            }} />
            <StackLogin.Screen name="Inscription" component={Inscription} options={{
                headerShown: false,
            }} />
            <StackLogin.Screen name="MotDePasse" component={MotDePasseOublie} options={{
                headerShown: false
            }} />
        </StackLogin.Navigator>
    </NavigationContainer>
);
}