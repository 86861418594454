import React from 'react';
import { Text, View,ScrollView } from "react-native";
import {componentstyle,textstyle} from "../assets/Style";
import Header from '../Component/Header';
import {ButtonBig} from'../Component/Button'
class Accueil extends React.Component {
	state = {
		
	}
	render() {
		return (
			<View style={componentstyle.container}>
				<Header/>
				<ScrollView>
				<View style={componentstyle.textcontainer}>
				<Text style={textstyle.titre2}>Bienvenue</Text>
				</View>
				<View style={componentstyle.textcontainer}>
				<Text style={textstyle.text}>Veuillez vous connectez ou vous inscrire pour accéder aux données de vos appareils</Text>
				</View>
				<ButtonBig onPress={() => this.props.navigation.navigate('Connexion')}>Se Connecter</ButtonBig>
				<ButtonBig onPress={() => this.props.navigation.navigate('Inscription')}>S'inscrire</ButtonBig>
				</ScrollView>
			</View>
			
		)
	}
}

export default Accueil

