import { StyleSheet, Dimensions } from 'react-native';
import { colors } from 'react-native-elements';
import {APP_COLORS} from './Color'


const textstyle = StyleSheet.create({
    titre: {
        fontSize: 68,
        textAlign: 'center',
        color: APP_COLORS.text,
        fontWeight: 'bold'
    },
    titre2: {
        fontSize: 36,
        textAlign: 'center',
        color: APP_COLORS.text,
        fontWeight: 'bold'
    },
    soustitre: {
        fontSize: 36,
        color: APP_COLORS.text,
        fontWeight: 'bold',
        textDecorationLine:'underline',
        paddingTop:30
    },
    
    input: {
        fontSize:24,
        color: APP_COLORS.text,
        backgroundColor:'#FFFFFF',
        borderBottomColor: '#000000',
        borderWidth:1,
        width:200
    },
    inputmultiple: {
        fontSize:16,
        color: APP_COLORS.text,
        backgroundColor:'#FFFFFF',
        borderBottomColor: '#000000',
        borderWidth:1,
        height:100,
        width:200
    },
    inputmultiplebig: {
        fontSize:16,
        color: APP_COLORS.text,
        backgroundColor:'#FFFFFF',
        borderBottomColor: '#000000',
        borderWidth:1,
        height:300,
        width:1200
    },
    text: {
        fontSize:24,
        textAlign:'justify',
        color:APP_COLORS.text,
        paddingLeft:5,
        paddingBottom:10
    },
    textbold: {
        fontSize:24,
        textAlign:'justify',
        fontWeight:'bold',
        color:APP_COLORS.text,
        paddingBottom:10
    },
    textbutton: {
        fontSize:36,
        textAlign:'center',
        color:APP_COLORS.textbutton
    },
    link: {
        paddingLeft:5,
        fontSize:24,
        color:APP_COLORS.link,
        textDecorationLine:'underline'
    }

});

const componentstyle = StyleSheet.create({
    image: {
        width:200,
        height:200,
    },
    imagecontainer: {
        justifyContent : "center",
        alignItems : "center",
        paddingStart: 50,
        paddingEnd: 50
    },
    componentstandard:{
        paddingStart:5
    },
    imagebig: {
        width:400,
        height:400,
    },
    button: {
        color: APP_COLORS.textbutton,
        backgroundColor: APP_COLORS.button,
        height: 100,
        width: 500,
        alignSelf:'center',
        textAlignVertical: 'center',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight:'bold',
        fontSize:20,
        alignContent:'center'
    },
    tab: {
        flexDirection:'row',
        paddingTop:20,
        alignSelf:'center'
    },
    tabcontent: {
        flex:1,
        width:Dimensions.get('window').width/6,
        borderWidth:1,
        alignItems:'center',
        backgroundColor:APP_COLORS.withe
    },
    bigButton: {
        color: APP_COLORS.textbutton,
        backgroundColor: APP_COLORS.button,
        height: 150,
        width: 500,
        alignSelf:'center',
        textAlignVertical: 'center',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight:'bold',
        fontSize:20,
        alignContent:'center'
    },
    littleButton: {
        color: APP_COLORS.textbutton,
        backgroundColor: APP_COLORS.button,
        height: 100,
        width: 200,
        alignSelf:'center',
        textAlignVertical: 'center',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight:'bold',
        fontSize:20
    },
    Picker: {
        borderWidth: 1,
        color: APP_COLORS.text,
        backgroundColor: '#FFFFFF',
        borderRadius: 10,
        fontSize:24,
        width:300
    },
    NavigationButton:{
        borderWidth: 1,
        color: APP_COLORS.textbutton,
        backgroundColor: APP_COLORS.button,
        borderRadius: 10,
        height:50,
    },
    buttonForm: {
        flex: 1,
        height: 40,
        textAlignVertical: 'center',
        fontSize:20

    },
    container: {
		flex: 1,
		backgroundColor:APP_COLORS.background
    },
    textcontainer: {
        paddingTop:20,
        paddingStart:20,
		alignSelf: 'center',
    },
    buttoncontainer: {
        alignSelf: 'center',
        paddingTop: 50
    },
    littleButtoncontainer:{
        alignSelf: 'center',
        paddingTop: 20
    },
    form : {
        flexDirection:'row',
        paddingTop:40,
        alignSelf:'center',
        alignItems:'center'
    },
    formelement: {
        flex:1,
        flexDirection:'row',
        width:300
    },
    formelementlittle: {
        flexDirection:'row',
        width:200
    },
    standardView: {
        paddingTop:20
    }

    

});
export { componentstyle }
export { textstyle }