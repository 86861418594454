import Header from "../Component/Header"
import React from "react"
import { Text, View, Image, ScrollView,TouchableOpacity } from "react-native";
import { componentstyle, textstyle } from "../assets/Style";
import { ButtonStandard } from "../Component/Button";
import { BigInputFormMutliple } from "../Component/Input";
import { Picker } from "@react-native-picker/picker";
import AsyncStorage from "@react-native-async-storage/async-storage";
import Moment from 'moment';
import axios from 'axios';
import config from '../config'
import { InputForm } from '../Component/Input';
import { TouchableHighlightBase } from "react-native";

var SystemInfos = [];  
class EditAppareilScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            type: "",
        }
    }

    state = {
        timestampState: "2021-02-06T21:33:36.000Z",
        deviceState: "exemple",
        softwareVersionState: "exemple",
        cameraState: "exemple",
        idCalibrationState : "exemple",
        idLedsState : "exemple",
        idResistorState : "exemple",
		led1s: "exemple",
        led2s: "exemple",
        led3s: "exemple",
        led4s: "exemple",
        led5s: "exemple",
        res1s: "exemple",
        res2s: "exemple",
        res3s: "exemple",
        res4s: "exemple",
        res5s: "exemple",
        shutterspeed1s: "123",
        awb1s: "124",
        stddev1s: "125",
        shutterspeed2s: "123",
        awb2s: "124",
        stddev2s: "125",
        shutterspeed3s: "123",
        awb3s: "124",
        stddev3s: "125",
        shutterspeed4s: "123",
        awb4s: "124",
        stddev4s: "125",
        shutterspeed5s: "123",
        awb5s: "124",
        stddev5s: "125",

	}
    switchEditLed() {
		this.state.editLeds = true
        this.state.editLedsButton = true
		this.forceUpdate()
		console.log(this.state.editLeds)
	}

    switchEditResistor() {
		this.state.editResistor = true
        this.state.editResistorButton = true
		this.forceUpdate()
		console.log(this.state.edit)
	}

    switchEdit() {
		this.state.edit = true
        this.state.editLeds = true
        this.state.editResistor = true
		this.forceUpdate()
		console.log(this.state.edit)
	}


    editleds() {
        console.log(SystemInfos)

        axios.put(config.backEndURL + config.backEndApiURL + 'leds',[{
            id : this.state.idLedsState,
            led0ref: this.state.led0s,
            led1ref: this.state.led1s,
            led2ref: this.state.led2s,
            led3ref: this.state.led3s,
            led4ref: this.state.led4s,
            led5ref: this.state.led5s
        }])
        .then((res) => {
            console.log(res)
        })
        this.state.editLeds = false
        this.state.editLedsButton = false
		this.forceUpdate()
    }

    editresistors() {
        console.log("EDit résistor !!!!")
        console.log(this.state.idResistorState)
        console.log(this.state.idLedsState)
        axios.put(config.backEndURL + config.backEndApiURL + 'resistors/'+ this.state.idResistorState,{
            r0ref: this.state.res0s,
            r1ref: this.state.res1s,
            r2ref: this.state.res2s,
            r3ref: this.state.res3s,
            r4ref: this.state.res4s,
            r5ref: this.state.res5s
        })
        .then((res) => {
            console.log(res)
        })
        this.state.editResistor = false
        this.state.editResistorButton = false
		this.forceUpdate()
    }

    save() {
        console.log('save AFTER put the bouton')
        console.log(config.backEndURL + config.backEndApiURL + 'leds')
		
        axios.post(config.backEndURL + config.backEndApiURL + 'leds', [{
            led0ref: this.state.led0s,
            led1ref: this.state.led1s,
            led2ref: this.state.led2s,
            led3ref: this.state.led3s,
            led4ref: this.state.led4s,
            led5ref: this.state.led5s
		}])
			.then((res) => {
				console.log(res)
                console.log(res.data.insertedIds)
                console.log("Salut")

                axios.post(config.backEndURL + config.backEndApiURL + 'resistors',[{
                    id : res.data.insertedIds,
                    r0ref: this.state.res0s,
                    r1ref: this.state.res1s,
                    r2ref: this.state.res2s,
                    r3ref: this.state.res3s,
                    r4ref: this.state.res4s,
                    r5ref: this.state.res5s
                }])
                .then((res) => {
                    console.log(res) 
                })
                axios.post(config.backEndURL + config.backEndApiURL + 'calibratedValues',[{
                    id : res.data.insertedIds, 
                    shutterspeed0 : "0",
                    awb0 : "0",
                    stddev0 : "0",
                    shutterspeed1 : "0",
                    awb1 : "0",
                    stddev1 : "0",
                    shutterspeed2 : "0",
                    awb2 : "0",
                    stddev2 : "0",
                    shutterspeed3 : "0",
                    awb3 : "0",
                    stddev3 : "0",
                    shutterspeed4 : "0",
                    awb4 : "0",
                    stddev4 : "0"

                }])
                .then((res) => {
                    console.log(res) 
                })
                axios.post(config.backEndURL + config.backEndApiURL + 'systeminfos/synchronize',[{
                    id: res.data.insertedIds,
                    systemId : this.state.deviceState,
                    timestamp : this.state.timestampState,
                    softwareVersion : this.state.softwareVersionState,
                    calibratedValuesId : "1",
                    cameraRef : this.state.cameraState,
                    ledsId : "1",
                    resistorsId : "1"
                }])
                .then((res) => {
                    console.log(res) 
                    console.log("finito") 
                })
        })
    

        
		this.state.edit = false
        this.state.editLeds = false
        this.state.editResistor = false
        this.state.editLedsButton = false
        this.state.editResistorButton = false
		this.forceUpdate()
	}

    ledsVal={
        led0: "0",
        led1: "1",
        led2: "2",
        led3: "3",
        led4: "4",
        led5: "5",
    }
    resVal={
        res0: "0",
        res1: "1",
        res2: "2",
        res3: "3",
        res4: "4",
        res5: "5",
    }
    calVal={
        shutterspeed1: "1",
        awb1: "1",
        stddev1: "1",
        shutterspeed2: "2",
        awb2: "2",
        stddev2: "2",
        shutterspeed3: "3",
        awb3: "3",
        stddev3: "3",
        shutterspeed4: "4",
        awb4: "4",
        stddev4: "4",
        shutterspeed5: "5",
        awb5: "5",
        stddev5: "5",
    }
    data={
        client: "Buffet Raphael",
        date: "12.01.2012",
        humidity: "20",
        note: "8"
    }
    data2={
        client: "Buffet Raphael",
        date: "12.01.2012",
        humidity: "20",
        note: "8"
    }

    SystemInfos={
        device: "22",
        timestamp: "2021-02-06T21:33:36.000Z",
        softwareVersion: "1.1.1",
        cameraRef: "pi Cam v2",
        idCalibration: "3",
        idLeds: "2",
        idResistor: "1"
    }

    componentDidMount(){
        setTimeout(() => {
            SystemInfos = [];            
            AsyncStorage.getItem('device2')
                .then(device3 => {
                    device3 = JSON.parse(device3)
                    this.data=device3

                    // Prendre les ID existant et 
                    axios.get(config.backEndURL + config.backEndApiURL + "leds")
                    .then((res) => {
                        console.log(res.data.id)
                    })

                    axios.get(config.backEndURL + config.backEndApiURL + "systeminfos/fromdevice/" + this.data.id)
                    .then((res) => {
                        console.log(res)
                        console.log(res.data.systemInfos.id)
                        console.log(res.data.systemInfos.softwareVersion)
                        
                        this.SystemInfos.id = res.data.systemInfos.id, // ID du système ID
                        this.SystemInfos.device = device3.id, // ID du device
                        this.SystemInfos.timestamp = res.data.systemInfos.timestamp,
                        this.SystemInfos.softwareVersion =  res.data.systemInfos.softwareVersion,
                        this.SystemInfos.cameraRef = res.data.systemInfos.cameraReference,
                        this.SystemInfos.idCalibration = res.data.systemInfos.idCalibratedValues;
                        this.SystemInfos.idLeds = res.data.systemInfos.idLeds;
                        this.SystemInfos.idResistor = res.data.systemInfos.idResistors

                        /*SystemInfos.push({
                            id: res.data.systemInfos.id, // ID du système ID
                            device : device3.id, // ID du device
                            timestamp: res.data.systemInfos.timestamp,
                            softwareVersion: res.data.systemInfos.softwareVersion,
                            cameraRef: res.data.systemInfos.cameraReference,
                            idCalibration: res.data.systemInfos.id,
                            idLeds: res.data.systemInfos.id,
                            idResistor: res.data.systemInfos.id
                        })*/
                        console.log("System Info")
                        console.log(SystemInfos)
                        axios.get(config.backEndURL + config.backEndApiURL + "leds/" + res.data.systemInfos.idLeds)
                        .then((leds) => {
                            //∂his.state.led0s = leds.data.led0ref,
                            this.ledsVal.led0 = leds.data.led0ref,
                            this.ledsVal.led1 = leds.data.led1ref,
                            this.ledsVal.led2 = leds.data.led2ref,
                            this.ledsVal.led3 = leds.data.led3ref,
                            this.ledsVal.led4 = leds.data.led4ref,
                            this.ledsVal.led5 = leds.data.led5ref
                        })
                        axios.get(config.backEndURL + config.backEndApiURL + "resistors/" + res.data.systemInfos.id)
                        .then((resistors) => {
                            this.resVal.res0 = resistors.data.resistor0ref,
                            this.resVal.res1 = resistors.data.resistor1ref,
                            this.resVal.res2 = resistors.data.resistor2ref,
                            this.resVal.res3 = resistors.data.resistor3ref,
                            this.resVal.res4 = resistors.data.resistor4ref,
                            this.resVal.res5 = resistors.data.resistor5ref
                        })
                        axios.get(config.backEndURL + config.backEndApiURL + "calibratedvalues/" + res.data.systemInfos.id)
                        .then((calVal) => {
                            this.calVal.shutterspeed1 = calVal.data.shutterspeed1,
                            this.calVal.awb1 = calVal.data.awb1,
                            this.calVal.stddev1 = calVal.data.stddev1,
                            this.calVal.shutterspeed2 = calVal.data.shutterspeed2,
                            this.calVal.awb2 = calVal.data.awb2,
                            this.calVal.stddev2 = calVal.data.stddev2,
                            this.calVal.shutterspeed3 = calVal.data.shutterspeed3,
                            this.calVal.awb3 = calVal.data.awb3,
                            this.calVal.stddev3 = calVal.data.stddev3,
                            this.calVal.shutterspeed4 = calVal.data.shutterspeed4,
                            this.calVal.awb4 = calVal.data.awb4,
                            this.calVal.stddev4 = calVal.data.stddev4,
                            this.calVal.shutterspeed5 = calVal.data.shutterspeed5,
                            this.calVal.awb5 = calVal.data.awb5,
                            this.calVal.stddev5 = calVal.data.stddev5
                        })
                    })
                })



            setTimeout(() => {
                console.log("setTimeOut")
                this.setState({

                    timestampState: this.SystemInfos.timestamp,
                    deviceState: this.SystemInfos.device,
                    softwareVersionState: this.SystemInfos.softwareVersion,
                    cameraState: this.SystemInfos.cameraRef,
                    idCalibrationState : this.SystemInfos.idCalibration,
                    idLedsState : this.SystemInfos.idLeds,
                    idResistorState : this.SystemInfos.idResistor,
                    
                    led0s: this.ledsVal.led0,
                    led1s: this.ledsVal.led1,
                    led2s: this.ledsVal.led2,
                    led3s: this.ledsVal.led3,
                    led4s: this.ledsVal.led4,
                    led5s: this.ledsVal.led5,

                    res0s: this.resVal.res0,
                    res1s: this.resVal.res1,
                    res2s: this.resVal.res2,
                    res3s: this.resVal.res3,
                    res4s: this.resVal.res4,
                    res5s: this.resVal.res5,

                    shutterspeed1s: this.calVal.shutterspeed1,
                    awb1s: this.calVal.awb1,
                    stddev1s: this.calVal.stddev1,
                    shutterspeed2s: this.calVal.shutterspeed2,
                    awb2s: this.calVal.awb2,
                    stddev2s: this.calVal.stddev2,
                    shutterspeed3s: this.calVal.shutterspeed3,
                    awb3s: this.calVal.awb3,
                    stddev3s: this.calVal.stddev3,
                    shutterspeed4s: this.calVal.shutterspeed4,
                    awb4s: this.calVal.awb4,
                    stddev4s: this.calVal.stddev4,
                    shutterspeed5s: this.calVal.shutterspeed5,
                    awb5s: this.calVal.awb5,
                    stddev5s: this.calVal.stddev5,
                })
            }, 600)
        },800)

    }
    detailImage(){
        this.props.navigation.navigate("DetailsImage")
    }
    render() {
        return (
            <View style={componentstyle.container}>
                <Header />
                <ScrollView>
                    <View style={componentstyle.form}>
                        <View style={componentstyle.textcontainer}>
                            {console.log("DEVICE")}
                            {console.log(this.SystemInfos.softwareVersion)}
                            <Text style={textstyle.soustitre}>ID device : {this.state.deviceState} </Text>
                        </View>
                        </View>
                        <View style={componentstyle.textcontainer}>
                        </View>
                        <View style={componentstyle.textcontainer}>

                        <View style={componentstyle.textcontainer}>
                            <Text style={textstyle.textbold}>Date de création du système info : {this.state.timestampState}</Text>
                        </View>

                        </View>
                        <View style={componentstyle.textcontainer}>
                        <View style={componentstyle.textcontainer}>
                            <Text style={textstyle.textbold}>Version du software:</Text>

                        {!this.state.edit ?
								<View style={componentstyle.formelementlittle}>
									<Text style={textstyle.text}>{this.state.softwareVersionState}</Text>
								</View>
								:
								<InputForm
									onChangeText={(softwareVersionState) => this.setState({ softwareVersionState })}
									value={this.state.softwareVersionState}
									secure={false}
								/>
							}
                        </View>


                        </View>
                        <View style={componentstyle.textcontainer}>

                        <View style={componentstyle.textcontainer}>
                            <Text style={textstyle.textbold}>Caméra utilisée: </Text>
                            {!this.state.edit ?
								<View style={componentstyle.formelementlittle}>
									<Text style={textstyle.text}>{this.state.cameraState}</Text>
								</View>
								:
								<InputForm
									onChangeText={(cameraState) => this.setState({ cameraState })}
									value={this.state.cameraState}
									secure={false}
								/>
							}
                        </View>

                        
                    </View>
                    <View style={componentstyle.form}>
                        <Text style={textstyle.soustitre}>LED</Text>
                    </View>
                    <View style={componentstyle.form}>
                        {console.log(this.ledsVal)}
                       
                        <View style={componentstyle.textcontainer}>
                            <Text style={textstyle.textbold}>Led 0 :</Text>
                            {!this.state.editLeds ?
								<View style={componentstyle.formelementlittle}>
									<Text style={textstyle.text}>{this.state.led0s}</Text>
								</View>
								:
								<InputForm
									onChangeText={(led0s) => this.setState({ led0s })}
									value={this.state.led0s}
									secure={false}
								/>
							}
                        </View>

                        <View style={componentstyle.textcontainer}>
                            <Text style={textstyle.textbold}>Led 1 :</Text>
                            {!this.state.editLeds ?
								<View style={componentstyle.formelementlittle}>
									<Text style={textstyle.text}>{this.state.led1s}</Text>
								</View>
								:
								<InputForm
									onChangeText={(led1s) => this.setState({ led1s })}
									value={this.state.led1s}
									secure={false}
								/>
							}
                        </View>

                        <View style={componentstyle.textcontainer}>
                            <Text style={textstyle.textbold}>Led 2 :</Text>
                            {!this.state.editLeds ?
								<View style={componentstyle.formelementlittle}>
									<Text style={textstyle.text}>{this.state.led2s}</Text>
								</View>
								:
								<InputForm
									onChangeText={(led2s) => this.setState({ led2s })}
									value={this.state.led2s}
									secure={false}
								/>
							}
                        </View>
                        </View>
                        <View style={componentstyle.form}>
                        <View style={componentstyle.textcontainer}>
                            <Text style={textstyle.textbold}>Led 3 :</Text>
                            {!this.state.editLeds ?
								<View style={componentstyle.formelementlittle}>
									<Text style={textstyle.text}>{this.state.led3s}</Text>
								</View>
								:
								<InputForm
									onChangeText={(led3s) => this.setState({ led3s })}
									value={this.state.led3s}
									secure={false}
								/>
							}
                        </View>

                        <View style={componentstyle.textcontainer}>
                            <Text style={textstyle.textbold}>Led 4 :</Text>
                            {!this.state.editLeds ?
								<View style={componentstyle.formelementlittle}>
									<Text style={textstyle.text}>{this.state.led4s}</Text>
								</View>
								:
								<InputForm
									onChangeText={(led4s) => this.setState({ led4s })}
									value={this.state.led4s}
									secure={false}
								/>
							}
                        </View>

                        <View style={componentstyle.textcontainer}>
                            <Text style={textstyle.textbold}>Led 5 :</Text>
                            {!this.state.editLeds ?
								<View style={componentstyle.formelementlittle}>
									<Text style={textstyle.text}>{this.state.led5s}</Text>
								</View>
								:
								<InputForm
									onChangeText={(led5s) => this.setState({ led5s })}
									value={this.state.led5s}
									secure={false}
								/>
							}
                        </View>
                        

                        </View>
                        <View style={componentstyle.textcontainer}>
                        {!this.state.editLedsButton ?
									<View>
										<ButtonStandard onPress={() => { this.switchEditLed() }}> Editer les Leds</ButtonStandard>
									</View>
									:
									<View>
										<ButtonStandard onPress={() => { this.editleds()}}> Sauvegarder </ButtonStandard>
									</View>}
                        </View>
                        <View style={componentstyle.form}>

                        <Text style={textstyle.soustitre}>Résitances</Text>
                        
                        </View>
                        <View style={componentstyle.form}>

                        <View style={componentstyle.textcontainer}>
                            <Text style={textstyle.textbold}>Résistance 0 :</Text>
                            {!this.state.editResistor ?
								<View style={componentstyle.formelementlittle}>
									<Text style={textstyle.text}>{this.state.res0s}</Text>
								</View>
								:
								<InputForm
									onChangeText={(res0s) => this.setState({ res0s })}
									value={this.state.res0s}
									secure={false}
								/>
							}
                        </View>

                        <View style={componentstyle.textcontainer}>
                            <Text style={textstyle.textbold}>Résistance 1 :</Text>
                            {!this.state.editResistor ?
								<View style={componentstyle.formelementlittle}>
									<Text style={textstyle.text}>{this.state.res1s}</Text>
								</View>
								:
								<InputForm
									onChangeText={(res1s) => this.setState({ res1s })}
									value={this.state.res1s}
									secure={false}
								/>
							}
                        </View>
                    

                        <View style={componentstyle.textcontainer}>
                            <Text style={textstyle.textbold}>Résistance 2 :</Text>
                            {!this.state.editResistor ?
								<View style={componentstyle.formelementlittle}>
									<Text style={textstyle.text}>{this.state.res2s}</Text>
								</View>
								:
								<InputForm
									onChangeText={(res2s) => this.setState({ res2s })}
									value={this.state.res2s}
									secure={false}
								/>
							}
                        </View>
                        </View>
                        <View style={componentstyle.form}>
                        <View style={componentstyle.textcontainer}>
                            <Text style={textstyle.textbold}> Résistance 3 :</Text>
                            {!this.state.editResistor ?
								<View style={componentstyle.formelementlittle}>
									<Text style={textstyle.text}>{this.state.res3s}</Text>
								</View>
								:
								<InputForm
									onChangeText={(res3s) => this.setState({ res3s })}
									value={this.state.res3s}
									secure={false}
								/>
							}
                        </View>

                        <View style={componentstyle.textcontainer}>
                            <Text style={textstyle.textbold}> Résistance 4 :</Text>
                            {!this.state.editResistor ?
								<View style={componentstyle.formelementlittle}>
									<Text style={textstyle.text}>{this.state.res4s}</Text>
								</View>
								:
								<InputForm
									onChangeText={(res4s) => this.setState({ res4s })}
									value={this.state.res4s}
									secure={false}
								/>
							}
                        </View>

                        <View style={componentstyle.textcontainer}>
                            <Text style={textstyle.textbold}>Résistance 5 :</Text>
                            {!this.state.editResistor ?
								<View style={componentstyle.formelementlittle}>
									<Text style={textstyle.text}>{this.state.res5s}</Text>
								</View>
								:
								<InputForm
									onChangeText={(res5s) => this.setState({ res5s })}
									value={this.state.res5s}
									secure={false}
								/>
							}
                        </View>

                        </View>
                        <View style={componentstyle.textcontainer}>
                        {!this.state.editResistor ?
									<View>
                                    <ButtonStandard onPress={() => { this.switchEditResistor() }}> Editer les Résistance</ButtonStandard>
                                </View>
                                :
                                <View>
                                    <ButtonStandard onPress={() => { this.editresistors()}}> Sauvegarder </ButtonStandard>
                                </View>}
                        </View>
                        <View style={componentstyle.form}>
                        <Text style={textstyle.soustitre}>Valeurs de calibrations</Text>
                        </View>
                        <View style={componentstyle.form}>

                        <View style={componentstyle.textcontainer}>
                            <Text style={textstyle.textbold}> Shutterspeed 1 :</Text>
                            {!this.state.edit ?
								<View style={componentstyle.formelementlittle}>
									<Text style={textstyle.text}>{this.state.shutterspeed1s}</Text>
								</View>
								:
								<InputForm
									onChangeText={(shutterspeed1s) => this.setState({ shutterspeed1s })}
									value={this.state.shutterspeed1s}
									secure={false}
								/>
							}
                        </View>
                        <View style={componentstyle.textcontainer}>
                            <Text style={textstyle.textbold}> Shutterspeed 2 :</Text>
                            {!this.state.edit ?
								<View style={componentstyle.formelementlittle}>
									<Text style={textstyle.text}>{this.state.shutterspeed2s}</Text>
								</View>
								:
								<InputForm
									onChangeText={(shutterspeed2s) => this.setState({ shutterspeed2s })}
									value={this.state.shutterspeed2s}
									secure={false}
								/>
							}
                        </View>
                        <View style={componentstyle.textcontainer}>
                            <Text style={textstyle.textbold}> Shutterspeed 3 :</Text>
                            {!this.state.edit ?
								<View style={componentstyle.formelementlittle}>
									<Text style={textstyle.text}>{this.state.shutterspeed3s}</Text>
								</View>
								:
								<InputForm
									onChangeText={(shutterspeed3s) => this.setState({ shutterspeed3s })}
									value={this.state.shutterspeed3s}
									secure={false}
								/>
							}
                        </View>
                        </View>
                        <View style={componentstyle.form}>

                        <View style={componentstyle.textcontainer}>
                            <Text style={textstyle.textbold}> Shutterspeed 4 :</Text>
                            {!this.state.edit ?
								<View style={componentstyle.formelementlittle}>
									<Text style={textstyle.text}>{this.state.shutterspeed4s}</Text>
								</View>
								:
								<InputForm
									onChangeText={(shutterspeed4s) => this.setState({ shutterspeed4s })}
									value={this.state.shutterspeed4s}
									secure={false}
								/>
							}
                        </View>
                        <View style={componentstyle.textcontainer}>
                            <Text style={textstyle.textbold}> Shutterspeed 5 :</Text>
                            {!this.state.edit ?
								<View style={componentstyle.formelementlittle}>
									<Text style={textstyle.text}>{this.state.shutterspeed5s}</Text>
								</View>
								:
								<InputForm
									onChangeText={(shutterspeed5s) => this.setState({ shutterspeed5s })}
									value={this.state.shutterspeed5s}
									secure={false}
								/>
							}
                        </View>



                        </View>
                        <View style={componentstyle.form}>
                        <View style={componentstyle.textcontainer}>
                            <Text style={textstyle.textbold}> AWB 1 :</Text>
                            {!this.state.edit ?
								<View style={componentstyle.formelementlittle}>
									<Text style={textstyle.text}>{this.state.awb1s}</Text>
								</View>
								:
								<InputForm
									onChangeText={(awb1s) => this.setState({ awb1s })}
									value={this.state.awb1s}
									secure={false}
								/>
							}
                        </View>

                        <View style={componentstyle.textcontainer}>
                            <Text style={textstyle.textbold}> AWB 2 :</Text>
                            {!this.state.edit ?
								<View style={componentstyle.formelementlittle}>
									<Text style={textstyle.text}>{this.state.awb2s}</Text>
								</View>
								:
								<InputForm
									onChangeText={(awb2s) => this.setState({ awb2s })}
									value={this.state.awb2s}
									secure={false}
								/>
							}
                        </View>
                        <View style={componentstyle.textcontainer}>
                            <Text style={textstyle.textbold}> AWB 3 :</Text>
                            {!this.state.edit ?
								<View style={componentstyle.formelementlittle}>
									<Text style={textstyle.text}>{this.state.awb3s}</Text>
								</View>
								:
								<InputForm
									onChangeText={(awb3s) => this.setState({ awb3s })}
									value={this.state.awb3s}
									secure={false}
								/>
							}
                        </View>
                        </View>
                        <View style={componentstyle.form}>

                        <View style={componentstyle.textcontainer}>
                            <Text style={textstyle.textbold}> AWB 4 :</Text>
                            {!this.state.edit ?
								<View style={componentstyle.formelementlittle}>
									<Text style={textstyle.text}>{this.state.awb4s}</Text>
								</View>
								:
								<InputForm
									onChangeText={(awb4s) => this.setState({ awb4s })}
									value={this.state.awb4s}
									secure={false}
								/>
							}
                        </View>
                        <View style={componentstyle.textcontainer}>
                            <Text style={textstyle.textbold}> AWB 5 :</Text>
                            {!this.state.edit ?
								<View style={componentstyle.formelementlittle}>
									<Text style={textstyle.text}>{this.state.awb5s}</Text>
								</View>
								:
								<InputForm
									onChangeText={(awb5s) => this.setState({ awb5s })}
									value={this.state.awb5s}
									secure={false}
								/>
							}
                        </View>

                        </View>
                        <View style={componentstyle.form}>
                        <View style={componentstyle.textcontainer}>
                            <Text style={textstyle.textbold}> STD dev 1 :</Text>
                            {!this.state.edit ?
								<View style={componentstyle.formelementlittle}>
									<Text style={textstyle.text}>{this.state.stddev1s}</Text>
								</View>
								:
								<InputForm
									onChangeText={(stddev1s) => this.setState({ stddev1s })}
									value={this.state.stddev1s}
									secure={false}
								/>
							}
                        </View>

                        <View style={componentstyle.textcontainer}>
                            <Text style={textstyle.textbold}> STD dev 2 :</Text>
                            {!this.state.edit ?
								<View style={componentstyle.formelementlittle}>
									<Text style={textstyle.text}>{this.state.stddev2s}</Text>
								</View>
								:
								<InputForm
									onChangeText={(stddev2s) => this.setState({ stddev2s })}
									value={this.state.stddev2s}
									secure={false}
								/>
							}
                        </View>
                        <View style={componentstyle.textcontainer}>
                            <Text style={textstyle.textbold}> STD dev 3 :</Text>
                            {!this.state.edit ?
								<View style={componentstyle.formelementlittle}>
									<Text style={textstyle.text}>{this.state.stddev3s}</Text>
								</View>
								:
								<InputForm
									onChangeText={(stddev3s) => this.setState({ stddev3s })}
									value={this.state.stddev3s}
									secure={false}
								/>
							}
                        </View>
                        </View>
                        <View style={componentstyle.form}>

                        <View style={componentstyle.textcontainer}>
                            <Text style={textstyle.textbold}> STD dev 4 :</Text>
                            {!this.state.edit ?
								<View style={componentstyle.formelementlittle}>
									<Text style={textstyle.text}>{this.state.stddev4s}</Text>
								</View>
								:
								<InputForm
									onChangeText={(stddev4s) => this.setState({ stddev4s })}
									value={this.state.stddev4s}
									secure={false}
								/>
							}
                        </View>
                        <View style={componentstyle.textcontainer}>
                            <Text style={textstyle.textbold}> STD dev 5 :</Text>
                            {!this.state.edit ?
								<View style={componentstyle.formelementlittle}>
									<Text style={textstyle.text}>{this.state.stddev5s}</Text>
								</View>
								:
								<InputForm
									onChangeText={(stddev5s) => this.setState({ stddev5s })}
									value={this.state.stddev5s}
									secure={false}
								/>
							}
                        </View>
                            



                    </View>
                    {!this.state.edit ?
									<View>
										<ButtonStandard onPress={() => { this.switchEdit() }}>Créer système ID</ButtonStandard>
                                        <ButtonStandard onPress={() => { this.props.navigation.goBack()}}> Retour </ButtonStandard>
									</View>
									:
									<View>
										<ButtonStandard onPress={() => { this.save() }}> Savegarder le système ID </ButtonStandard>
										<ButtonStandard onPress={() => { this.props.navigation.goBack()}}> Retour </ButtonStandard>
									</View>}
                </ScrollView>
            </View>
        )
    }
}

export default EditAppareilScreen
